import React, { useCallback, useEffect, useState } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  ButtonPrimary,
  MainNavigation,
  MainNavigationContent,
} from "@/design-system";
import { useUrlTypeformAiTaskCreator } from "@/helpers-ts";
import ConfirmModal from "../../components/ConfirmModal";
import { Modal2 } from "../../components/Modal";
import TaskPreview from "../../components/TaskPreview";
import TestForm2 from "../../components/TestForm2";
import Loading from "../../containers/Loading";
import {
  SHOW_DEV_TOOLS,
  textCantChangeTaskType,
  textCantEditTest,
  usePrevious,
} from "../../helpers";
import { useDefaultOrderQuantity } from "../../hooks/useDefaultOrderQuantity";
import { useUrlChecker } from "../../hooks/useUrlChecker";
import Navigation from "../Navigation";
import Notifications from "../Notifications";

import { getTaskTemplate } from "./templates";

import styles from "./styles.module.css";

import imageSquircleBottle from "./squircle-bottle-fs8.png";

const TestSetup = (props) => {
  const params = useParams();
  const [testType, setTestType] = useState(props.testType || "website");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [overwriteUrlCheck, setOverwriteUrlCheck] = useState(false);
  const prevOverwriteUrlCheck = usePrevious(overwriteUrlCheck);
  const [language, setLanguage] = useState("en");
  const [task, setTask] = useState(null);
  const [creationType, setCreationType] = useState(null);
  const [originalTask, setOriginalTask] = useState(null);
  const [goToTestOnSaveSuccess, setGoToTestOnSaveSuccess] = useState(false);
  const [orderTestsOnSaveSuccess, setOrderTestsOnSaveSuccess] = useState(false);
  const [promptNavigation, setPromptNavigation] = useState(false);
  const [previewActive, setPreviewActive] = useState(false);
  const [previewInitialStep, setPreviewInitialStep] = useState(0);

  // Will be set to false if nothing needs to be loaded or if loading (duplicate, edit, create from template) is done
  const [waitingForTestToLoad, setWaitingForTestToLoad] = useState(true);

  const defaultOrderQuantity = useDefaultOrderQuantity();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { urlCheckerDone, urlCheckerOk, urlCheckerMessage } =
    useUrlChecker(url);

  const {
    loadFetching,
    loadSuccess,
    loadError,
    loadedTest,
    duplicateLoadFetching,
    duplicateLoadSuccess,
    duplicateLoadError,
    loadedDuplicate,
    templateLoadFetching,
    templateLoadSuccess,
    templateLoadError,
    loadedTemplate,
    saveFetching,
    saveSuccess,
    saveError,
    saveTestId,
    //
    isAiTestSetupFetching,
    isAiTestSetupSuccess,
    aiTestSetupPrompt,
    aiTestSetupResult,
    aiTestSetupId,
    aiTestSetupError,
  } = useSelector((state) => state.testSetup);
  const prevTemplateLoadFetching = usePrevious(templateLoadFetching);
  const prevDuplicateLoadFetching = usePrevious(duplicateLoadFetching);
  const prevLoadFetching = usePrevious(loadFetching);
  const prevSaveFetching = usePrevious(saveFetching);

  let customErrorMessage = null;

  if (saveError?.type === "has_running_deliveries") {
    customErrorMessage = textCantEditTest;
  } else if (saveError?.type === "task_type_changed") {
    customErrorMessage = textCantChangeTaskType;
  }

  const urlTypeformAiTaskCreatorSadEmotion = useUrlTypeformAiTaskCreator(
    "sad",
    aiTestSetupId,
  );
  const urlTypeformAiTaskCreatorNeutralEmotion = useUrlTypeformAiTaskCreator(
    "neutral",
    aiTestSetupId,
  );
  const urlTypeformAiTaskCreatorHappyEmotion = useUrlTypeformAiTaskCreator(
    "happy",
    aiTestSetupId,
  );

  /* Init */
  useEffect(() => {
    dispatch({ type: "TEST_SETUP_RESET" });
    if (params.duplicateId) {
      dispatch({ type: "TEST_SETUP_DUPLICATE_LOAD", id: params.duplicateId });
      // setDuplicate(true); // Default from beginning? Nevermind delete this comment if everything works
    } else if (params.id) {
      dispatch({ type: "TEST_SETUP_LOAD", id: params.id });
      // XXX: We should in the future refactor this to 'example' semantic
    } else if (params.templateId) {
      dispatch({
        type: "TEST_SETUP_TEMPLATE_LOAD",
        templateId: params.templateId,
      });
    } else {
      // Nothing to load
      setWaitingForTestToLoad(false);
    }
  }, [dispatch, params.duplicateId, params.id, params.templateId]);

  useEffect(() => {
    if (
      duplicateLoadFetching === false &&
      prevDuplicateLoadFetching === true &&
      duplicateLoadSuccess === true
    ) {
      setTestType(loadedDuplicate.type);
      setTitle(loadedDuplicate.title);
      setTask(loadedDuplicate.task);
      setOriginalTask(loadedDuplicate.task);
      setUrl(loadedDuplicate.url);
      setLanguage(loadedDuplicate.language);
      setOverwriteUrlCheck(false); // Does not yet exist in resource, always false
      setWaitingForTestToLoad(false);
    }
  }, [
    duplicateLoadFetching,
    duplicateLoadSuccess,
    loadedDuplicate,
    prevDuplicateLoadFetching,
  ]);

  useEffect(() => {
    if (
      templateLoadFetching === false &&
      prevTemplateLoadFetching === true &&
      templateLoadSuccess === true
    ) {
      setTask(loadedTemplate.task);
      setOriginalTask(loadedTemplate.task);
      setWaitingForTestToLoad(false);
    }
  }, [
    templateLoadFetching,
    prevTemplateLoadFetching,
    templateLoadSuccess,
    loadedTemplate,
  ]);

  useEffect(() => {
    if (
      loadFetching === false &&
      prevLoadFetching === true &&
      loadSuccess === true
    ) {
      // When test is loaded, set the state to the loaded test
      // But if test cant be edited, navigate to test detail instead

      if (loadedTest.has_unfinished_deliveries === true) {
        navigate("/test/" + loadedTest.id, {
          state: { cantEditTest: true },
        });
      } else {
        setTestType(loadedTest.type);
        setTitle(loadedTest.title);
        setTask(loadedTest.task);
        setOriginalTask(loadedTest.task);
        setUrl(loadedTest.url);
        setLanguage(loadedTest.language);
        setOverwriteUrlCheck(false); // Does not yet exist in resource, always false
        setWaitingForTestToLoad(false);
      }
    }
  }, [loadFetching, loadSuccess, loadedTest, prevLoadFetching, navigate]);

  useEffect(() => {
    if (isAiTestSetupFetching === true && aiTestSetupResult) {
      setTask(aiTestSetupResult);
    }
  }, [aiTestSetupResult, isAiTestSetupFetching]);

  // justSaved is used to prevent the prompt if navigate is called before setPromptNavigation() ist done
  const justSaved = saveFetching === false && prevSaveFetching === true;

  const navigationBlocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      promptNavigation &&
      !justSaved &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  useEffect(() => {
    if (saveFetching === false && prevSaveFetching === true) {
      if (saveSuccess === true) {
        setPromptNavigation(false);

        if (goToTestOnSaveSuccess) {
          navigate("/test/" + saveTestId);
          dispatch({
            type: "SNACKBAR_ADD",
            notificationType: "success",
            content: "Test saved successfully",
          });
        } else if (orderTestsOnSaveSuccess) {
          navigate("/test/" + saveTestId);
          dispatch({
            type: "ORDER_TESTERS_INIT_AND_OPEN_MODAL",
            quantity: defaultOrderQuantity,
            testId: parseInt(saveTestId, 10),
          });
        } else if (!params.id) {
          // In case it is a new or duplicate test forward to the edit screen of the
          // newly created test
          navigate(`/test/${saveTestId}/setup`);
        }

        // Todo: Save to do this for next state as componentDidUpdate does query prevState
        setGoToTestOnSaveSuccess(false);
        setOrderTestsOnSaveSuccess(false);
      } else {
        // Scroll to top if error
        window.scrollTo(0, 0);
      }
    }
  }, [
    saveFetching,
    prevSaveFetching,
    saveSuccess,
    saveTestId,
    dispatch,
    goToTestOnSaveSuccess,
    orderTestsOnSaveSuccess,
    navigate,
    params.id,
    defaultOrderQuantity,
  ]);

  useEffect(() => {
    const handleWindowBeforeUnload = (event) => {
      if (promptNavigation) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleWindowBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleWindowBeforeUnload);
    };
  }, [promptNavigation]);

  const save = useCallback(() => {
    const parseCreationType = (() => {
      if (params.duplicateId) {
        return "duplicate";
      } else if (params.templateId) {
        return "website-template";
      } else {
        return creationType === "auto" ? "ai" : creationType;
      }
    })();

    dispatch({
      type: "TEST_SETUP_SAVE",
      id: params.id,
      language,
      task,
      testType,
      title,
      url,
      urlChecked: urlCheckerDone && urlCheckerOk,
      overwriteUrlCheck,
      aitest_id: aiTestSetupId,
      creation_type: parseCreationType,
    });
  }, [
    dispatch,
    params.id,
    params.duplicateId,
    params.templateId,
    language,
    task,
    testType,
    title,
    url,
    urlCheckerDone,
    urlCheckerOk,
    overwriteUrlCheck,
    aiTestSetupId,
    creationType,
  ]);

  useEffect(() => {
    if (overwriteUrlCheck === true && prevOverwriteUrlCheck === false) {
      save();
    }
  }, [overwriteUrlCheck, prevOverwriteUrlCheck, save]);

  function handleClickPreviewTaskStep(stepIndex) {
    setPreviewInitialStep(stepIndex);
    setPreviewActive(true);
  }

  function handleClickShowPreview() {
    setPreviewInitialStep(0);
    setPreviewActive(true);
  }

  function handleClickHidePreview() {
    setPreviewActive(false);
  }

  function handleClickOrderTesters() {
    setOrderTestsOnSaveSuccess(true);
    save();
  }

  function handleClickSaveAndClose() {
    setGoToTestOnSaveSuccess(true);
    save();
  }

  function handleClickSave() {
    save();
  }

  function handleChangeTitle(e) {
    setTitle(e.target.value);
    setPromptNavigation(true);
  }

  function handleChangeUrl(e) {
    setUrl(e.target.value);
    setPromptNavigation(true);
  }

  function handleChangeLanguage(language) {
    setLanguage(language);
    setPromptNavigation(true);
  }

  function handleChangeTask(task) {
    setTask(task);
    setPromptNavigation(true);
  }

  function handleOverwriteUrlCheck() {
    // There is an effect that saves the test if overwrite url check changes from false to true
    setOverwriteUrlCheck(true);
  }

  function handleRequestAuto(prompt) {
    dispatch({
      type: "TEST_SETUP_AI_REQUEST",
      prompt: prompt,
    });
  }

  function handleSelectCreationType(creationType) {
    setCreationType(creationType);
    if (creationType === "template") {
      handleChangeTask(
        getTaskTemplate({
          language,
          type: testType,
        }),
      );
    } else if (creationType === "scratch") {
      handleChangeTask(getTaskTemplate({ language, type: "blank" }));
    } else {
      setTask(null);
    }
  }

  function handleStartOver() {
    // XXX: If AI is fetching start over should not be possible…
    setTask(null);
    setCreationType(null);
  }

  if (
    templateLoadFetching ||
    duplicateLoadFetching ||
    loadFetching ||
    loadError ||
    waitingForTestToLoad ||
    duplicateLoadError ||
    templateLoadError
  ) {
    return (
      <Loading error={loadError || duplicateLoadError || templateLoadError}>
        Loading Test
      </Loading>
    );
  }

  const isActionsDisabled = saveFetching || isAiTestSetupFetching;
  const isHideActions =
    !params.id &&
    !params.duplicateId &&
    (!task?.length || creationType === null || isAiTestSetupFetching);

  return (
    <>
      <Helmet>
        <title>{title || "New Test"} | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <div className={`${styles.content}`}>
            {params.templateId && (
              <div className={styles.templateSuccessInfo}>
                <img
                  src={imageSquircleBottle}
                  width="72px"
                  alt="Champagne bottle"
                />
                <div>
                  We‘ve already prefilled tasks and questions from your selected
                  template.
                </div>
              </div>
            )}

            <TestForm2
              isExistingTest={!!params.id}
              aiTestSetupPrompt={aiTestSetupPrompt}
              aiTestSetupError={aiTestSetupError}
              className={styles.testForm2}
              errorMessage={customErrorMessage || saveError?.message}
              fieldFeedback={saveError?.fieldFeedback}
              isAiTestSetupFetching={isAiTestSetupFetching}
              isAiTestSetupSuccess={isAiTestSetupSuccess}
              language={language}
              onAutoRequest={handleRequestAuto}
              onChangeLanguage={handleChangeLanguage}
              onChangeTask={handleChangeTask}
              onChangeTitle={handleChangeTitle}
              onChangeUrl={handleChangeUrl}
              onStartOver={handleStartOver}
              creationType={creationType}
              onSelectCreationType={handleSelectCreationType}
              onClickPreviewTaskStep={handleClickPreviewTaskStep}
              onOverwriteUrlCheck={handleOverwriteUrlCheck}
              originalTask={originalTask}
              task={task}
              testType={testType}
              title={title}
              url={url}
              urlCheckerDone={urlCheckerDone}
              urlCheckerMessage={urlCheckerMessage}
              urlCheckerOk={urlCheckerOk}
              urlTypeformAiTaskCreatorSadEmotion={
                urlTypeformAiTaskCreatorSadEmotion
              }
              urlTypeformAiTaskCreatorNeutralEmotion={
                urlTypeformAiTaskCreatorNeutralEmotion
              }
              urlTypeformAiTaskCreatorHappyEmotion={
                urlTypeformAiTaskCreatorHappyEmotion
              }
            />

            {!isHideActions && (
              <div className={styles.footer}>
                <Button
                  disabled={isActionsDisabled}
                  onClick={handleClickShowPreview}
                >
                  <FontAwesomeIcon icon={solid("eye")} fixedWidth /> Preview
                </Button>

                <Button
                  disabled={isActionsDisabled}
                  onClick={handleClickSaveAndClose}
                >
                  {saveFetching && goToTestOnSaveSuccess ? (
                    "Saving…"
                  ) : (
                    <>Save and close</>
                  )}
                </Button>

                {SHOW_DEV_TOOLS && (
                  <Button
                    disabled={isActionsDisabled}
                    onClick={handleClickSave}
                  >
                    {saveFetching &&
                    !orderTestsOnSaveSuccess &&
                    !goToTestOnSaveSuccess ? (
                      "Saving…"
                    ) : (
                      <>Save (DEV)</>
                    )}
                  </Button>
                )}

                <ButtonPrimary
                  onClick={handleClickOrderTesters}
                  disabled={isActionsDisabled}
                >
                  {saveFetching && orderTestsOnSaveSuccess ? (
                    "Saving…"
                  ) : (
                    <>
                      Get testers
                      <FontAwesomeIcon
                        icon={regular("chevron-right")}
                        style={{
                          fontSize: "1em",
                          marginLeft: "0.8em",
                        }}
                      />
                    </>
                  )}
                </ButtonPrimary>
              </div>
            )}
          </div>

          <Modal2
            isActive={previewActive}
            onClose={handleClickHidePreview}
            content={
              <TaskPreview
                task={task}
                initialStep={previewInitialStep}
                onClickClose={handleClickHidePreview}
              />
            }
          />

          <ConfirmModal
            isActive={navigationBlocker.state === "blocked"}
            onConfirm={() => navigationBlocker.proceed()}
            onClose={() => navigationBlocker.reset()}
            labelConfirm={"Leave this page"}
          >
            Are you sure you want to leave this page? You will lose any unsaved
            data.
          </ConfirmModal>
        </MainNavigationContent>
      </MainNavigation>
    </>
  );
};

export default TestSetup;
