import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FieldHelp, FormField, InputText } from "@/design-system";
import { Modal2 } from "../components/Modal";
import { ModalContent } from "../components/ModalContent";
import { ModalForm } from "../components/ModalForm";
import { usePrevious } from "../helpers";

import { useBillingInformation } from "./useBillingInformation";
import { useModal2 } from "./useModal2";

export function useEditBillingEmailModal() {
  const dispatch = useDispatch();

  const billingInformation = useBillingInformation();
  const defaultBillingEmail = useSelector(
    (state) => state.user.user?.email || "",
  );
  const [email, setEmail] = useState("");

  const updateFetching = useSelector(
    (state) => state.billingInformation.updateFetching,
  );
  const prevUpdateFetching = usePrevious(updateFetching);
  const updateError = useSelector(
    (state) => state.billingInformation.updateError,
  );

  function handleSubmitUpdateBillingEmailForm(e) {
    e.preventDefault();
    dispatch({
      type: "BILLING_INFORMATION_UPDATE_REQUEST",
      billingEmail: email,
    });
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);
  }

  const allDataAvailable = !!billingInformation;

  const { modal, showModal, hideModal, isModalActive } = useModal2(
    <Modal2
      loading={!allDataAvailable}
      content={
        allDataAvailable && (
          <ModalContent>
            <ModalForm
              title={"Billing email"}
              onSubmit={handleSubmitUpdateBillingEmailForm}
              fetching={updateFetching}
              buttonLabelSubmit={"Save changes"}
              buttonLabelSubmitFetching={"Saving changes…"}
              onClickCancel={handleClickCancel}
            >
              <FormField
                style={{ marginTop: "var(--spacing-stack-default)" }}
                label={"Where should we send your invoices?"}
                errorMessage={updateError?.fieldFeedback.billing_email}
              >
                <InputText
                  type="email"
                  placeholder={defaultBillingEmail}
                  value={email}
                  onChange={handleChangeEmail}
                />
                <FieldHelp>Leave empty to use your account email</FieldHelp>
              </FormField>
            </ModalForm>
          </ModalContent>
        )
      }
    />,
  );

  const prevModalActive = usePrevious(isModalActive);

  // Load data if modal is activated
  useEffect(() => {
    if (isModalActive === true && prevModalActive === false) {
      setEmail(billingInformation?.billingEmail || "");
    }
  }, [dispatch, isModalActive, prevModalActive, billingInformation]);

  // Close modal on save success
  useEffect(() => {
    if (
      prevUpdateFetching === true &&
      updateFetching === false &&
      Boolean(updateError) === false
    ) {
      hideModal();
    }
  });

  function handleClickCancel() {
    hideModal();
  }

  return {
    modal,
    showModal,
    hideModal,
  };
}
