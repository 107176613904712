import { useEffect, useMemo } from "react";
import { useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import debounce from "lodash/debounce";
import { Helmet } from "react-helmet";
import { usePageVisibility } from "react-page-visibility";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRevalidator, useSearchParams } from "react-router-dom";

import ConfirmDeleteModal from "@/components/ConfirmDeleteModal";
import { useModal2Controller } from "@/components/Modal/hooks";
import { NavChips } from "@/components/NavChips";
import {
  ButtonPrimary,
  MainNavigation,
  MainNavigationContent,
  Pagination,
  Search,
} from "@/design-system";
import { useHistoryStateAndClear } from "@/hooks/useHistoryStateAndClear";
import { selectIsUserOwnerOrAdmin } from "@/selectors/user";
import Spinner from "../../components/Spinner";
import Test from "../../components/Test";
import { usePrevious } from "../../helpers";
import { useComponentDidMount } from "../../hooks/useComponentDidMount";
import { useDefaultOrderQuantity } from "../../hooks/useDefaultOrderQuantity";
import Navigation from "../Navigation";
import { NewFeatureModal } from "../NewFeatureModal";
import Notifications from "../Notifications";

import styles from "./styles.module.css";

export default function Tests() {
  const [searchParams, setSearchParams] = useSearchParams();

  const templateId = useHistoryStateAndClear("templateId");

  const [currentNavItem, setCurrentNavItem] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(searchParams.get("q") ?? "");

  const tests = useSelector((state) => state.tests.tests);
  const testsError = useSelector((state) => state.tests.error);
  const testsFetching = useSelector((state) => state.tests.fetching);
  const archiveFetching = useSelector((state) => state.tests.archiveFetching);
  const prevArchiveFetching = usePrevious(archiveFetching);
  const unarchiveFetching = useSelector(
    (state) => state.tests.unarchiveFetching,
  );
  const prevUnarchiveFetching = usePrevious(unarchiveFetching);
  const deleteFetching = useSelector((state) => state.tests.deleteFetching);
  const deleteError = useSelector((state) => state.tests.deleteError);
  const prevDeleteFetching = usePrevious(deleteFetching);
  const testsTotalCount = useSelector((state) => state.tests.totalCount);
  const testsTotalCountTests = useSelector(
    (state) => state.tests.totalCountTests,
  );
  const testsTotalCountDraft = useSelector(
    (state) => state.tests.totalCountDraft,
  );
  const testsTotalCountArchived = useSelector(
    (state) => state.tests.totalCountArchived,
  );
  const testsPerPage = useSelector((state) => state.tests.perPage);
  const isOwnerOrAdmin = useSelector(selectIsUserOwnerOrAdmin);

  const defaultOrderQuantity = useDefaultOrderQuantity();
  const isPageVisible = usePageVisibility();
  const prevIsPageVisible = usePrevious(isPageVisible);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const {
    isOpen: isDeleteTestModalOpen,
    open: openDeleteTestModal,
    close: closeDeleteTestModal,
  } = useModal2Controller();

  const [deleteTestError, setDeleteTestError] = useState(null);
  const [deleteTestTestId, setDeleteTestTestId] = useState(null);

  // Open create test modal if templateId is in history state
  useComponentDidMount(() => {
    if (templateId) {
      dispatch({
        type: "GLOBAL_MODAL_OPEN",
        modal: "createTest",
        modalOptions: {
          templateId: templateId,
        },
      });
    }
  });

  const searchParamsString = searchParams.toString();

  const debouncedSetSearchParams = useMemo(() => {
    return debounce((newSearchParams) => {
      setSearchParams(newSearchParams);
    }, 500);
  }, [setSearchParams]);

  useEffect(() => {
    if (
      (prevArchiveFetching === true && archiveFetching === false) ||
      (prevUnarchiveFetching === true && unarchiveFetching === false)
    ) {
      revalidator.revalidate();
    }
  }, [
    prevArchiveFetching,
    archiveFetching,
    prevUnarchiveFetching,
    unarchiveFetching,
    searchParamsString,
    revalidator,
  ]);

  useEffect(() => {
    if (
      prevDeleteFetching === true &&
      deleteFetching === false &&
      !deleteError
    ) {
      closeDeleteTestModal();
      revalidator.revalidate();
    }
  }, [
    closeDeleteTestModal,
    prevDeleteFetching,
    deleteFetching,
    deleteError,
    revalidator,
  ]);

  useEffect(() => {
    if (searchParams.get("archived") === "1") {
      setCurrentNavItem("archived");
    } else if (searchParams.get("status") === "draft") {
      setCurrentNavItem("draft");
    } else {
      setCurrentNavItem("all");
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(parseInt(searchParams.get("page")));
    } else {
      setCurrentPage(1);
    }
  }, [searchParams]);

  useEffect(() => {
    const queryFromSearchParam = searchParams.get("q") ?? "";
    if (search !== queryFromSearchParam) {
      const newSearchParams = Object.fromEntries(searchParams.entries());
      if (search === "") {
        delete newSearchParams.q;
      } else {
        newSearchParams.q = search;
      }
      delete newSearchParams.page;
      debouncedSetSearchParams(newSearchParams);
    }
  }, [search, searchParams, debouncedSetSearchParams]);

  useEffect(() => {
    if (isPageVisible === true) {
      function reRequestTests() {
        dispatch({ type: "TESTS_RE_REQUEST" });
      }
      if (prevIsPageVisible === false) {
        reRequestTests();
      }
      const reloadTestsAndVideosInterval = setInterval(
        reRequestTests,
        process.env.REACT_APP_REFRESH_INTERVAL,
      );
      return () => {
        clearInterval(reloadTestsAndVideosInterval);
      };
    }
  }, [isPageVisible, prevIsPageVisible, dispatch]);

  function handleClickCreateNewTest() {
    dispatch({ type: "GLOBAL_MODAL_OPEN", modal: "createTest" });
  }

  function handleChangeSearch(e) {
    setSearch(e.target.value);
  }

  function handleClickDelete(test) {
    setDeleteTestError(null);
    setDeleteTestTestId(test.id);
    openDeleteTestModal();
  }

  function handleDeleteTest(confirmText) {
    if (confirmText === "DELETE") {
      setDeleteTestError(null);
      dispatch({ type: "TEST_DELETE", id: deleteTestTestId });
    } else {
      setDeleteTestError("Please type DELETE to confirm.");
    }
  }

  function handleClickArchive(test) {
    dispatch({ type: "TEST_ARCHIVE", id: test.id, title: test.title });
  }

  function handleClickUnarchive(test) {
    dispatch({ type: "TEST_UNARCHIVE", id: test.id, title: test.title });
  }

  function handleClickDuplicate(test) {
    navigate("/test/duplicate/" + test.id);
  }

  function handleClickRequestTestTest(test, status, processing) {
    dispatch({ type: "ORDER_TEST_TEST", testId: test.id, status, processing });
  }

  function handleClickRequestTestingTestTest(test, status) {
    dispatch({ type: "ORDER_TESTING_TEST_TEST", testId: test.id, status });
  }

  function handleClickRequestInvitationTestTest(test) {
    dispatch({ type: "ORDER_INVITATION_TEST_TEST", testId: test.id });
  }

  function handleClickGetTesters(test) {
    dispatch({
      type: "ORDER_TESTERS_INIT_AND_OPEN_MODAL",
      testId: test.id,
      quantity: defaultOrderQuantity,
    });
  }

  function handleClickPage(page) {
    const newSearchParams = Object.fromEntries(searchParams.entries());
    newSearchParams.page = page;
    if (page === 1) {
      delete newSearchParams.page;
    }
    setSearchParams(newSearchParams);
  }

  let emptyState = false;

  if (tests !== null && tests.length <= 0) {
    if (search) {
      emptyState = (
        <div className={styles.emptyState}>
          <div className={styles.emptyState__icon}>
            <FontAwesomeIcon icon={regular("info-circle")} fixedWidth />
          </div>
          <div className={styles.emptyState__text}>
            No tests were found matching your search request.
          </div>
        </div>
      );
    } else {
      switch (currentNavItem) {
        case "all":
          emptyState = (
            <div className={styles.emptyStateArrow}>
              <div className={styles.emptyStateArrow__text}>
                Start by creating your first&nbsp;test&nbsp;now!
              </div>
            </div>
          );
          break;
        case "archived":
          emptyState = (
            <div className={styles.emptyState}>
              <div className={styles.emptyState__icon}>
                <FontAwesomeIcon icon={regular("archive")} fixedWidth />
              </div>
              <div className={styles.emptyState__text}>
                There are currently no tests in your archive.
              </div>
            </div>
          );
          break;
        case "draft":
          emptyState = (
            <div className={styles.emptyState}>
              <div className={styles.emptyState__icon}>
                <FontAwesomeIcon icon={regular("file-alt")} fixedWidth />
              </div>
              <div className={styles.emptyState__text}>
                There are currently no drafts.
              </div>
            </div>
          );
          break;
        default:
          emptyState = (
            <div className={styles.emptyState}>
              <div className={styles.emptyState__icon}>
                <FontAwesomeIcon icon={regular("info-circle")} fixedWidth />
              </div>
              <div className={styles.emptyState__text}>
                There are no items that match your criteria.
              </div>
            </div>
          );
          break;
      }
    }
  }

  const hideSearch = tests?.length === 0 && currentNavItem === "all" && !search;

  return (
    <>
      <Helmet>
        <title>Dashboard | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />

          <div className={styles.content}>
            <div className={styles.contentHeader}>
              <h1 className={styles.mainHeading}>Dashboard</h1>
              <ButtonPrimary
                onClick={handleClickCreateNewTest}
                className={styles.createNewTest}
              >
                Create New Test
              </ButtonPrimary>
            </div>

            <div className={styles.navAndSearch}>
              <NavChips>
                <NavChips.Item
                  to={"/dashboard"}
                  isActive={currentNavItem === "all"}
                  count={testsTotalCountTests}
                >
                  All Tests
                </NavChips.Item>
                <NavChips.Item
                  to={"/dashboard?status=draft"}
                  isActive={currentNavItem === "draft"}
                  count={testsTotalCountDraft}
                >
                  Drafts
                </NavChips.Item>
                <NavChips.Item
                  to={"/dashboard?archived=1"}
                  isActive={currentNavItem === "archived"}
                  count={testsTotalCountArchived}
                >
                  Archive
                </NavChips.Item>
              </NavChips>
              <Search
                className={cn(
                  styles.searchBox,
                  /* Hide when empty state for all tests is active */
                  hideSearch && styles.searchBox_hidden,
                )}
                placeholder={"Search by title, URL, and tasks…"}
                value={search}
                onChange={handleChangeSearch}
              />
            </div>

            <div className={styles.tests}>
              {tests === null && testsFetching && <Spinner />}
              {tests === null && testsError && (
                <div className={styles.emptyState}>
                  <div className={styles.emptyState__icon}>
                    <FontAwesomeIcon icon={regular("cloud-slash")} fixedWidth />
                  </div>
                  <div className={styles.emptyState__text}>
                    {testsError.message}
                  </div>
                </div>
              )}
              {tests?.length === 0 && emptyState}
              {tests !== null &&
                tests.map((test) => (
                  <Test
                    key={test.id}
                    testId={test.id}
                    testType={test.type}
                    title={test.title}
                    status={test.status}
                    archived={test.archived}
                    invitation={test.invitation}
                    isOwnerOrAdmin={isOwnerOrAdmin}
                    isArchivable={test.is_archivable}
                    isDeleteable={test.is_deleteable}
                    hasRepeatDeliveries={test.has_repeat_deliveries}
                    countNewVideos={test.new_video_count}
                    countVideos={test.video_count}
                    countVideosOpen={test.open_video_count}
                    onClickDelete={() => handleClickDelete(test)}
                    onClickArchive={() => handleClickArchive(test)}
                    onClickUnarchive={() => handleClickUnarchive(test)}
                    onClickDuplicate={() => handleClickDuplicate(test)}
                    onClickRequestTestTest={(status, processing) =>
                      handleClickRequestTestTest(test, status, processing)
                    }
                    onClickRequestTestingTestTest={(status) =>
                      handleClickRequestTestingTestTest(test, status)
                    }
                    onClickRequestInvitationTestTest={() =>
                      handleClickRequestInvitationTestTest(test)
                    }
                    onClickGetTesters={() => handleClickGetTesters(test)}
                  />
                ))}
            </div>
            {tests?.length > 0 && (
              <Pagination
                className={styles.pagination}
                currentPage={currentPage}
                countPages={Math.ceil(testsTotalCount / testsPerPage)}
                onClickPage={handleClickPage}
              />
            )}
          </div>
        </MainNavigationContent>
      </MainNavigation>

      <NewFeatureModal />

      <ConfirmDeleteModal
        title="Are you sure you want to delete this test?"
        buttonLabel="Delete test"
        isActive={isDeleteTestModalOpen}
        onClose={closeDeleteTestModal}
        onSubmit={handleDeleteTest}
        isFetching={deleteFetching}
        errorMessage={deleteTestError ?? deleteError?.message}
      />
    </>
  );
}
