import React, { useEffect, useState } from "react";

import { ButtonPrimary, ErrorMessage } from "@/design-system";
import { usePrevious } from "../../helpers";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from "./styles.module.css";

export default function RemoveTeamMemberModal({
  isActive,
  onClose,
  ...restProps
}) {
  return (
    <Modal2
      isActive={isActive}
      maxWidth={"30rem"}
      onClose={onClose}
      content={
        <RemoveTeamMemberModalContent onClose={onClose} {...restProps} />
      }
    />
  );
}

function RemoveTeamMemberModalContent({
  member,
  onClose,
  fetching,
  error,
  onRemove,
}) {
  const { invitation_email: email } = member;
  const [removedSuccessfully, setRemovedSuccessfully] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const prevFetching = usePrevious(fetching);
  useEffect(() => {
    if (prevFetching === true && fetching === false) {
      if (!error) {
        setRemovedSuccessfully(true);
      } else {
        setDisplayError(true);
      }
    }
  }, [prevFetching, fetching, error]);

  function handleClickRemove() {
    onRemove(member);
  }
  return removedSuccessfully ? (
    <>
      <ModalHeader heading={"Team Member removed"} onClickCloseIcon={onClose} />
      <div className={styles.modalContent}>
        <p>{email} was removed from your team.</p>
        <p className={styles.subline}>
          Please contact support if you want to grant them access again.
        </p>
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  ) : (
    <>
      <ModalHeader heading={"Remove Team Member"} onClickCloseIcon={onClose} />
      <div className={styles.modalContent}>
        {error && displayError && <ErrorMessage message={error.message} />}

        <p>Are you sure you want to remove {email}?</p>
        <p className={styles.subline}>
          Their account will become a regular Userbrain account and they will no
          longer be able to access your team’s videos.
        </p>
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={handleClickRemove} disabled={fetching}>
            {fetching ? "Removing…" : "Yes, remove member"}
          </ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  );
}
