import React, { useEffect, useState } from "react";

import { Button, ButtonPrimary, ErrorMessage } from "@/design-system";
import { usePrevious } from "../../helpers";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from "./styles.module.css";

export default function ResendInviteModal({ isActive, onClose, ...restProps }) {
  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      maxWidth={"30rem"}
      content={<ResendInviteModalContent onClose={onClose} {...restProps} />}
    />
  );
}

function ResendInviteModalContent({
  member,
  onClose,
  fetching,
  error,
  onResend,
}) {
  const { invitation_email: email } = member;
  const [resentSuccessfully, setResentSuccessfully] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const prevFetching = usePrevious(fetching);
  useEffect(() => {
    if (prevFetching === true && fetching === false) {
      if (!error) {
        setResentSuccessfully(true);
      } else {
        setDisplayError(true);
      }
    }
  }, [prevFetching, fetching, error]);

  function handleClickResend() {
    onResend(member);
  }
  return resentSuccessfully ? (
    <>
      <ModalHeader heading={"Invite resent"} onClickCloseIcon={onClose} />
      <div className={styles.modalContent}>
        <p>An invite was sent to {email}.</p>
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  ) : (
    <>
      <ModalHeader heading={"Resend invite"} onClickCloseIcon={onClose} />
      <div className={styles.modalContent}>
        {error && displayError && <ErrorMessage message={error.message} />}

        <p>Are you sure you want to resend the invite to {email}?</p>
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={handleClickResend} disabled={fetching}>
            {fetching ? "Sending…" : "Yes, resend"}
          </ButtonPrimary>
          <Button onClick={onClose}>Cancel</Button>
        </ButtonGroup>
      </div>
    </>
  );
}
