// Confirm email
import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import { API_URL } from ".";

export function* watchConfirmEmailResend() {
  yield takeEvery("CONFIRM_EMAIL_RESEND_REQUEST", callConfirmEmailResendApi);

  yield takeEvery("CONFIRM_EMAIL_RESEND_SUCCESS", function* () {
    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Verification email sent`,
    });
  });

  yield takeEvery("CONFIRM_EMAIL_RESEND_FAILURE", function* (action: any) {
    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "error",
      content: action.error.message,
    });
  });
}

// Watch and take every CONFIRM_EMAIL_RESEND_SUCCESS and CONFIRM_EMAIL_RESEND_FAILURE
// actions

function* callConfirmEmailResendApi(): SagaIterator {
  try {
    const response = yield call(postConfirmEmailResend);
    yield put({ type: "CONFIRM_EMAIL_RESEND_SUCCESS", data: response.data });
  } catch (error) {
    yield put({
      type: "CONFIRM_EMAIL_RESEND_FAILURE",
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function postConfirmEmailResend() {
  return axios({
    method: "post",
    url: API_URL + "/user/confirm/resend",
  });
}
