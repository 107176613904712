import React, { Component } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";
import qs from "query-string";
import { Helmet } from "react-helmet";
import PageVisibility from "react-page-visibility";
import { connect } from "react-redux";

import { NavChips } from "@/components/NavChips";
import {
  DropDown,
  DropDownDialog,
  DropDownItem,
  FormField,
  MainNavigation,
  MainNavigationContent,
  Pagination,
  Search,
  Stack,
} from "@/design-system";
import Spinner from "../../components/Spinner";
import VideoListItem from "../../components/VideoListItem";
import { withRouter } from "../../helpers";
import Navigation from "../Navigation";
import Notifications from "../Notifications";

import styles from "./styles.module.css";

/* Snipped for tags */

/* {props.video.tags.map(tag => <span key={tag}>#{tag}&nbsp; </span>)} */

class Videos extends Component {
  constructor(props) {
    super(props);

    const {
      currentNavItem,
      currentPage,
      searchString,
      filterDevice,
      filterTestId,
    } = Videos.parseSearchParamsForState(
      qs.parse(props.router.location.search),
    );

    this.state = {
      currentNavItem,
      currentPage,
      searchString,
      filterDevice,
      filterTestId,
      filterDialogOpen: false,
      videos: [],
    };

    this.prevPageIsVisible = null;

    this.reloadVideosInterval = setInterval(
      this.props.reRequestVideos,
      process.env.REACT_APP_REFRESH_INTERVAL,
    );
    this.props.loadFilterOptions();
  }

  componentWillUnmount() {
    clearInterval(this.reloadVideosInterval);
  }

  static parseSearchParamsForState(query) {
    let currentNavItem = "all";
    if (query.new === "1") {
      currentNavItem = "new";
    } else if (query.favorites === "1") {
      currentNavItem = "favorites";
    }

    const currentPage =
      typeof query.page === "undefined" ? 1 : parseInt(query.page);
    const searchString = typeof query.q === "undefined" ? "" : query.q;
    const filterDevice =
      typeof query.device === "undefined" ? "" : query.device;
    const filterTestId =
      typeof query.test === "undefined" ? "" : parseInt(query.test);

    return {
      currentNavItem,
      currentPage,
      searchString,
      filterDevice,
      filterTestId,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.router.location.search !== this.props.router.location.search
    ) {
      const {
        currentNavItem,
        currentPage,
        searchString,
        filterDevice,
        filterTestId,
      } = Videos.parseSearchParamsForState(
        qs.parse(this.props.router.location.search),
      );

      this.setState({
        currentNavItem,
        currentPage,
        searchString,
        filterDevice,
        filterTestId,
      });
    }
  }

  handleClickVideo = (videoId) => {};

  handleVisibilityChange = (isVisible) => {
    clearInterval(this.reloadVideosInterval);
    if (isVisible) {
      if (this.prevPageIsVisible === false) {
        this.props.reRequestVideos();
      }
      this.reloadVideosInterval = setInterval(
        this.props.reRequestVideos,
        process.env.REACT_APP_REFRESH_INTERVAL,
      );
    }
    this.prevPageIsVisible = isVisible;
  };

  onClickPagination = (page) => {
    this.setState({ currentPage: page }, () => {
      this.pushCurrentStateToQuery();
    });
  };

  debouncedPushCurrentStateToQuery = debounce(() => {
    this.pushCurrentStateToQuery();
  }, 500);

  onSearch = (e) => {
    this.setState({ searchString: e.target.value, currentPage: 1 }, () => {
      this.debouncedPushCurrentStateToQuery();
    });
  };

  pushCurrentStateToQuery = () => {
    const { searchString, currentPage, filterDevice, filterTestId } =
      this.state;
    const query = qs.parse(this.props.router.location.search);
    if (searchString && searchString.length > 0) {
      query.q = searchString;
    } else {
      delete query.q;
    }
    if (currentPage > 1) {
      query.page = currentPage;
    } else {
      delete query.page;
    }
    if (filterDevice !== "") {
      query.device = filterDevice;
    } else {
      delete query.device;
    }
    if (filterTestId !== "") {
      query.test = filterTestId;
    } else {
      delete query.test;
    }
    this.props.router.navigate({ search: qs.stringify(query) });
  };

  render() {
    const {
      videos,
      videosError,
      videosFetching,
      filterOptionsTests,
      totalCountVideos,
      totalCountNew,
      totalCountStarred,
    } = this.props;
    const {
      currentNavItem,
      filterDevice,
      filterTestId,
      filterDialogOpen,
      searchString,
    } = this.state;

    let emptyState = false;

    if (
      videos?.length === 0 &&
      (searchString || filterDevice || filterTestId)
    ) {
      emptyState = (
        <div className={styles.emptyState}>
          <div className={styles.emptyState__icon}>
            <FontAwesomeIcon icon={regular("info-circle")} fixedWidth />
          </div>
          <div className={styles.emptyState__text}>
            No items matching your search criteria were found.
          </div>
        </div>
      );
    } else if (videos?.length === 0) {
      switch (currentNavItem) {
        case "new":
          emptyState = (
            <div className={styles.emptyState}>
              <div className={styles.emptyState__icon}>
                <FontAwesomeIcon icon={solid("info-circle")} fixedWidth />
              </div>
              <div className={styles.emptyState__text}>
                There are no new videos in your account.
              </div>
            </div>
          );
          break;
        case "favorites":
          emptyState = (
            <div className={styles.emptyState}>
              <div className={styles.emptyState__icon}>
                <FontAwesomeIcon icon={solid("heart")} fixedWidth />
              </div>
              <div className={styles.emptyState__text}>
                You have no favorites yet.
              </div>
            </div>
          );
          break;
        default:
          emptyState = (
            <div className={styles.emptyState}>
              <div className={styles.emptyState__icon}>
                <FontAwesomeIcon icon={regular("info-circle")} fixedWidth />
              </div>
              <div className={styles.emptyState__text}>
                You have no sessions yet.
              </div>
            </div>
          );
          break;
      }
    }

    const selectedFilterOptionTests = filterOptionsTests.find(
      (item) => item.id === filterTestId,
    );

    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <>
          <Helmet>
            <title>Sessions | Userbrain</title>
          </Helmet>
          <MainNavigation>
            <Navigation />
            <MainNavigationContent>
              <Notifications />

              <div className={styles.content}>
                <div className={styles.contentHeader}>
                  <h1 className={styles.mainHeading}>Sessions</h1>
                  <DropDownDialog
                    label={"Filter videos"}
                    onOpen={() => this.setState({ filterDialogOpen: true })}
                    onClose={() => this.setState({ filterDialogOpen: false })}
                    open={filterDialogOpen}
                    appearance={"button-primary"}
                    className={styles.filterVideos}
                  >
                    <Stack className={styles.dropDownDialogContent}>
                      <FormField label={"Tests"}>
                        <DropDown
                          value={filterTestId}
                          isSearchable={true}
                          onChange={(e) =>
                            this.setState(
                              {
                                filterTestId:
                                  e.target.value === ""
                                    ? ""
                                    : parseInt(e.target.value),
                                filterDialogOpen: false,
                              },
                              this.pushCurrentStateToQuery,
                            )
                          }
                        >
                          <DropDownItem value={""}>All</DropDownItem>
                          {filterOptionsTests.map((item) => (
                            <DropDownItem value={item.id} key={item.id}>
                              {item.title}
                            </DropDownItem>
                          ))}
                        </DropDown>
                      </FormField>
                      <FormField label={"Devices"}>
                        <DropDown
                          value={this.state.filterDevice}
                          onChange={(e) =>
                            this.setState(
                              {
                                filterDevice: e.target.value,
                                filterDialogOpen: false,
                              },
                              this.pushCurrentStateToQuery,
                            )
                          }
                        >
                          <DropDownItem value={""}>All</DropDownItem>
                          <DropDownItem value={"desktop"}>Desktop</DropDownItem>
                          <DropDownItem value={"tablet"}>Tablet</DropDownItem>
                          <DropDownItem value={"mobile"}>Mobile</DropDownItem>
                        </DropDown>
                      </FormField>
                    </Stack>
                  </DropDownDialog>
                </div>

                <div className={styles.navAndSearch}>
                  <NavChips>
                    <NavChips.Item
                      to={"/sessions"}
                      isActive={currentNavItem === "all"}
                      count={totalCountVideos}
                    >
                      All sessions
                    </NavChips.Item>
                    <NavChips.Item
                      to={"/sessions?new=1"}
                      isActive={currentNavItem === "new"}
                      count={totalCountNew}
                    >
                      New sessions
                    </NavChips.Item>
                    <NavChips.Item
                      to={"/sessions?favorites=1"}
                      isActive={currentNavItem === "favorites"}
                      count={totalCountStarred}
                    >
                      Favorites
                    </NavChips.Item>
                  </NavChips>
                  <Search
                    placeholder={"Search tests, URLs, and #hashtags…"}
                    value={this.state.searchString}
                    onChange={this.onSearch}
                    className={styles.searchBox}
                  />
                </div>

                <div className={`${styles.videos} card`}>
                  {(filterDevice || filterTestId) && (
                    <div className={styles.filterStatus}>
                      <button
                        className={styles.filterStatus__reset}
                        onClick={() =>
                          this.setState(
                            {
                              filterDevice: "",
                              filterTestId: "",
                            },
                            this.pushCurrentStateToQuery,
                          )
                        }
                      >
                        <FontAwesomeIcon icon={solid("times-circle")} />
                      </button>
                      <div className={styles.filterStatus__label}>
                        Filtered by:&nbsp;
                      </div>

                      {filterDevice && (
                        <div className={styles.filterStatus__item}>
                          Device: {filterDevice}
                        </div>
                      )}
                      {filterTestId && (
                        <div className={styles.filterStatus__item}>
                          Test:&nbsp;
                          {selectedFilterOptionTests ? (
                            <span className={styles.filterStatus__testTitle}>
                              "{selectedFilterOptionTests.title}"
                            </span>
                          ) : (
                            filterTestId
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {videos === null && videosFetching && <Spinner />}
                  {videos === null && videosError && (
                    <div className={styles.emptyState}>
                      <div className={styles.emptyState__icon}>
                        <FontAwesomeIcon
                          icon={regular("cloud-slash")}
                          fixedWidth
                        />
                      </div>
                      <div className={styles.emptyState__text}>
                        {videosError.message}
                      </div>
                    </div>
                  )}
                  {videos?.length === 0 && emptyState}
                  {videos?.length > 0 && (
                    <div className={styles.videoList}>
                      {videos.map((video) => (
                        <VideoListItem
                          type={"video"}
                          video={video}
                          onClick={() => this.handleClickVideo(video.id)}
                          showTitle={true}
                          key={video.id}
                        />
                      ))}
                    </div>
                  )}
                </div>
                {videos?.length > 0 && (
                  <Pagination
                    className={styles.pagination}
                    currentPage={this.state.currentPage}
                    countPages={Math.ceil(
                      this.props.videosTotalCount / this.props.videosPerPage,
                    )}
                    onClickPage={this.onClickPagination}
                  />
                )}
              </div>
            </MainNavigationContent>
          </MainNavigation>
        </>
      </PageVisibility>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    videos: state.videos.videos,
    videosError: state.videos.error,
    filterOptionsTests: state.videos.videosFilterOptionsTests,
    videosFetching: state.videos.fetching,
    fetchingStar: state.videos.fetchingStar,
    fetchingUnstar: state.videos.fetchingUnstar,
    videosTotalCount: state.videos.totalCount,
    videosPerPage: state.videos.perPage,
    totalCountVideos: state.videos.totalCountVideos,
    totalCountNew: state.videos.totalCountNew,
    totalCountStarred: state.videos.totalCountStarred,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reRequestVideos: () => dispatch({ type: "VIDEOS_RE_REQUEST" }),
    loadFilterOptions: () => dispatch({ type: "VIDEOS_TESTS_REQUEST" }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Videos));
