import { Reducer } from "@reduxjs/toolkit";

import { CHECKOUT_INIT } from "./checkout";
import { ApiError } from "./types";

// ORDER TESTS

const ORDER_TESTS_REQUEST = "ORDER_TESTS_REQUEST";
const ORDER_TESTS_WAITING_FOR_CONFIRM = "ORDER_TESTS_WAITING_FOR_CONFIRM";
const ORDER_TESTS_CONFIRM = "ORDER_TESTS_CONFIRM";
const ORDER_TESTS_CONFIRM_FAILED = "ORDER_TESTS_CONFIRM_FAILED";
const ORDER_TESTS_SUCCESS = "ORDER_TESTS_SUCCESS";
const ORDER_TESTS_FAILURE = "ORDER_TESTS_FAILURE";
const ORDER_TESTS_CLEAR_ERRORS = "ORDER_TESTS_CLEAR_ERRORS";

type OrderTestsState = {
  clientSecret: string;
  deliveryError: any; // Todo
  deliveryErrorMessage: string;
  error: ApiError | null;
  fetching: boolean;
  success: boolean;
  waitingForConfirmation: boolean;
};

const initialStateOrderTests: OrderTestsState = {
  clientSecret: "",
  deliveryError: null,
  deliveryErrorMessage: "",
  error: null,
  fetching: false,
  success: false,
  waitingForConfirmation: false,
};

export const orderTestsReducer: Reducer<OrderTestsState> = function (
  state = initialStateOrderTests,
  action,
) {
  switch (action.type) {
    case CHECKOUT_INIT:
      // Reset Error message when new checkout is started
      return {
        ...state,
        error: initialStateOrderTests.error,
      };
    case ORDER_TESTS_REQUEST:
      return {
        ...state,
        error: null,
        success: false,
        fetching: true,
        waitingForConfirmation: false,
        deliveryError: initialStateOrderTests.deliveryError,
        deliveryErrorMessage: initialStateOrderTests.deliveryErrorMessage,
      };
    case ORDER_TESTS_WAITING_FOR_CONFIRM:
      return {
        ...state,
        waitingForConfirmation: true,
        clientSecret: action.clientSecret,
      };
    case ORDER_TESTS_CONFIRM:
      return { ...state, waitingForConfirmation: false };
    case ORDER_TESTS_CONFIRM_FAILED:
      return { ...state, waitingForConfirmation: false };
    case ORDER_TESTS_SUCCESS:
      const deliveryError = action.deliveryError ?? null;
      const deliveryErrorMessage = action.deliveryErrorMessage ?? "";
      return {
        ...state,
        fetching: false,
        error: null,
        success: true,
        waitingForConfirmation: false,
        deliveryError,
        deliveryErrorMessage,
      };
    case ORDER_TESTS_FAILURE:
      const error = action.error;
      return {
        ...state,
        fetching: false,
        success: false,
        error,
        waitingForConfirmation: false,
      };
    case ORDER_TESTS_CLEAR_ERRORS: {
      const { error, deliveryErrorMessage, deliveryError } =
        initialStateOrderTests;
      return {
        ...state,
        error,
        deliveryErrorMessage,
        deliveryError,
      };
    }
    default:
      return state;
  }
};
