import { convertApiScreenerToScreener } from "@/helpers";
import { stepTargeting } from "../containers/OrderTestsModal/steps";
import { TEST_INVITATION_UPDATE_STATUS_SUCCESS } from ".";

// ORDER TESTERS
// const ORDER_TESTERS_TEST_REQUEST = "ORDER_TESTERS_TEST_REQUEST";
const ORDER_TESTERS_TEST_SUCCESS = "ORDER_TESTERS_TEST_SUCCESS";
const ORDER_TESTERS_TEST_FAILURE = "ORDER_TESTERS_TEST_FAILURE";
const ORDER_TESTERS_SET_QUANTITY = "ORDER_TESTERS_SET_QUANTITY";
const ORDER_TESTERS_SET_DEVICE = "ORDER_TESTERS_SET_DEVICE";
const ORDER_TESTERS_SET_REGION = "ORDER_TESTERS_SET_REGION";
const ORDER_TESTERS_SET_AGE = "ORDER_TESTERS_SET_AGE";
const ORDER_TESTERS_SET_GENDER = "ORDER_TESTERS_SET_GENDER";
const ORDER_TESTERS_SET_SCREENER = "ORDER_TESTERS_SET_SCREENER";
const ORDER_TESTERS_SET_SCREENER_ENABLED = "ORDER_TESTERS_SET_SCREENER_ENABLED";
const ORDER_TESTERS_INIT_AND_OPEN_MODAL = "ORDER_TESTERS_INIT_AND_OPEN_MODAL";
const ORDER_TESTERS_OPEN_MODAL = "ORDER_TESTERS_OPEN_MODAL";
const ORDER_TESTERS_CLOSE_MODAL = "ORDER_TESTERS_CLOSE_MODAL";

const initialStateOrderTesters = {
  modalActive: false,
  initialStep: stepTargeting,
  test: null,
  testFetching: false,
  testError: null,
  quantity: 5,
  device: null,
  region: null,
  age: null,
  gender: null,
  screenerEnabled: false,
  screener: null,
};

export function orderTestersReducer(state = initialStateOrderTesters, action) {
  switch (action.type) {
    case ORDER_TESTERS_CLOSE_MODAL:
      return { ...state, modalActive: false };
    case ORDER_TESTERS_OPEN_MODAL:
      return { ...state, modalActive: true };
    case ORDER_TESTERS_INIT_AND_OPEN_MODAL:
      const {
        quantity,
        device,
        region,
        age,
        gender,
        screener,
        screenerEnabled,
        test,
        testError,
      } = initialStateOrderTesters;

      return {
        ...state,
        test,
        testError,
        quantity: action.quantity ?? quantity,
        device: action.targeting?.device ?? device,
        region: action.targeting?.region ?? region,
        age: action.targeting?.age ?? age,
        gender: action.targeting?.gender ?? gender,
        screenerEnabled: action.targeting?.screenerEnabled ?? screenerEnabled,
        screener: action.targeting?.screener ?? screener,
        modalActive: true,
        testFetching: true,
        initialStep: action.initialStep ?? initialStateOrderTesters.initialStep,
      };
    case TEST_INVITATION_UPDATE_STATUS_SUCCESS: {
      const { test } = action;
      if (test.id === state.test?.id) {
        return {
          ...state,
          test,
        };
      }
      return state;
    }
    case ORDER_TESTERS_TEST_SUCCESS: {
      const device = action.test.targeting_settings?.device || state.device;
      let quantity = state.quantity;
      const orderedVideoCount = action.test.latest_delivery_ordered_video_count;
      if (orderedVideoCount) {
        quantity = Object.entries(orderedVideoCount).reduce(
          (acc, curr) => acc + curr[1],
          0,
        );
      }
      const screenerEnabled = !!action.test.targeting_settings?.screener_active;
      const screener = convertApiScreenerToScreener(
        action.test.targeting_settings?.screener,
      );
      return {
        ...state,
        test: action.test,
        testFetching: false,
        testError: null,
        quantity,
        device,
        region:
          action.test.targeting_settings?.region ??
          initialStateOrderTesters.region,
        age:
          action.test.targeting_settings?.age ?? initialStateOrderTesters.age,
        gender:
          action.test.targeting_settings?.gender ??
          initialStateOrderTesters.gender,
        screener: screener ?? initialStateOrderTesters.screener,
        screenerEnabled,
      };
    }
    case ORDER_TESTERS_TEST_FAILURE:
      return {
        ...state,
        test: null,
        testFetching: false,
        testError: action.error,
      };
    case ORDER_TESTERS_SET_QUANTITY:
      return {
        ...state,
        quantity: action.quantity,
      };
    case ORDER_TESTERS_SET_DEVICE:
      return {
        ...state,
        device: action.device,
      };
    case ORDER_TESTERS_SET_REGION:
      return {
        ...state,
        region: action.region,
      };
    case ORDER_TESTERS_SET_AGE:
      return {
        ...state,
        age: action.age,
      };
    case ORDER_TESTERS_SET_GENDER:
      return {
        ...state,
        gender: action.gender,
      };
    case ORDER_TESTERS_SET_SCREENER:
      return {
        ...state,
        screener: action.screener,
      };
    case ORDER_TESTERS_SET_SCREENER_ENABLED:
      return {
        ...state,
        screenerEnabled: action.screenerEnabled,
      };
    default:
      return state;
  }
}
