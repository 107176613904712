const TEST_SETUP_SAVE = "TEST_SETUP_SAVE";
const TEST_SETUP_SAVE_SUCCESS = "TEST_SETUP_SAVE_SUCCESS";
const TEST_SETUP_SAVE_FAILURE = "TEST_SETUP_SAVE_FAILURE";

const TEST_SETUP_LOAD = "TEST_SETUP_LOAD";
const TEST_SETUP_LOAD_SUCCESS = "TEST_SETUP_LOAD_SUCCESS";
const TEST_SETUP_LOAD_FAILURE = "TEST_SETUP_LOAD_FAILURE";

const TEST_SETUP_DUPLICATE_LOAD = "TEST_SETUP_DUPLICATE_LOAD";
const TEST_SETUP_DUPLICATE_LOAD_SUCCESS = "TEST_SETUP_DUPLICATE_LOAD_SUCCESS";
const TEST_SETUP_DUPLICATE_LOAD_FAILURE = "TEST_SETUP_DUPLICATE_LOAD_FAILURE";

const TEST_SETUP_TEMPLATE_LOAD = "TEST_SETUP_TEMPLATE_LOAD";
const TEST_SETUP_TEMPLATE_LOAD_SUCCESS = "TEST_SETUP_TEMPLATE_LOAD_SUCCESS";
const TEST_SETUP_TEMPLATE_LOAD_FAILURE = "TEST_SETUP_TEMPLATE_LOAD_FAILURE";

const TEST_SETUP_AI_REQUEST = "TEST_SETUP_AI_REQUEST";
const TEST_SETUP_AI_PROGRESS = "TEST_SETUP_AI_PROGRESS";
const TEST_SETUP_AI_SUCCESS = "TEST_SETUP_AI_SUCCESS";
const TEST_SETUP_AI_FAILURE = "TEST_SETUP_AI_FAILURE";

const TEST_SETUP_RESET = "TEST_SETUP_RESET";

const initialStateTestSetup = {
  saveFetching: false,
  saveSuccess: false,
  saveError: null,
  saveTestId: null,
  //
  loadFetching: false,
  loadSuccess: false,
  loadError: null,
  loadedTest: null,
  //
  duplicateLoadFetching: false,
  duplicateLoadSuccess: false,
  duplicateLoadError: null,

  //
  loadedDuplicate: null,
  loadedTemplate: null,
  //
  templateLoadFetching: false,
  templateLoadSuccess: false,
  templateLoadError: null,
  //
  isAiTestSetupFetching: false,
  isAiTestSetupSuccess: false,
  aiTestSetupError: null,

  aiTestSetupPrompt: null,
  aiTestSetupResult: null,
  aiTestSetupId: null,
};

export function testSetupReducer(state = initialStateTestSetup, action) {
  switch (action.type) {
    case TEST_SETUP_AI_REQUEST:
      return {
        ...state,
        isAiTestSetupFetching: true,
        isAiTestSetupSuccess: false,
        aiTestSetupPrompt: action.prompt,
        aiTestSetupResult: null,
        aiTestSetupId: null,
        aiTestSetupError: null,
      };
    // This reducer is called with the full result of the auto test setup
    // and not just the latest progress. So a replace is done here.
    case TEST_SETUP_AI_PROGRESS:
      return {
        ...state,
        aiTestSetupResult: action.result,
        // This id is of the AI generated API
        // We need to post it to the save endpoint & Typeform feedback
        aiTestSetupId: action.aiTestSetupId,
      };
    // This reducer simply signals that the auto test setup was successful
    // and does not set any new test state.
    case TEST_SETUP_AI_SUCCESS:
      return {
        ...state,
        isAiTestSetupFetching: false,
        isAiTestSetupSuccess: true,
      };
    case TEST_SETUP_AI_FAILURE:
      return {
        ...state,
        isAiTestSetupFetching: false,
        isAiTestSetupSuccess: false,
        aiTestSetupId: null,
        aiTestSetupError: action.error,
      };
    case TEST_SETUP_LOAD:
      return { ...state, loadFetching: true };
    case TEST_SETUP_LOAD_SUCCESS: {
      const { test } = action;
      return {
        ...state,
        loadFetching: false,
        loadSuccess: true,
        loadedTest: test,
      };
    }
    case TEST_SETUP_LOAD_FAILURE: {
      return {
        ...state,
        loadFetching: false,
        loadSuccess: false,
        loadError: action.error,
      };
    }
    case TEST_SETUP_DUPLICATE_LOAD:
      return { ...state, duplicateLoadFetching: true };
    case TEST_SETUP_DUPLICATE_LOAD_SUCCESS: {
      const { testDuplicate } = action;
      return {
        ...state,
        duplicateLoadFetching: false,
        duplicateLoadSuccess: true,
        loadedDuplicate: testDuplicate,
      };
    }
    case TEST_SETUP_DUPLICATE_LOAD_FAILURE: {
      return {
        ...state,
        duplicateLoadFetching: false,
        duplicateLoadSuccess: false,
        duplicateLoadError: action.error,
      };
    }
    case TEST_SETUP_TEMPLATE_LOAD:
      return { ...state, templateLoadFetching: true };
    case TEST_SETUP_TEMPLATE_LOAD_SUCCESS: {
      const { template } = action;
      return {
        ...state,
        templateLoadFetching: false,
        templateLoadSuccess: true,
        loadedTemplate: template,
      };
    }
    case TEST_SETUP_TEMPLATE_LOAD_FAILURE: {
      return {
        ...state,
        templateLoadFetching: false,
        templateLoadSuccess: false,
        templateLoadError: action.error,
      };
    }
    case TEST_SETUP_SAVE:
      return {
        ...state,
        saveError: null,
        saveSuccess: false,
        saveFetching: true,
      };
    case TEST_SETUP_SAVE_SUCCESS: {
      const newState = {
        ...state,
        saveFetching: false,
        saveError: null,
        saveSuccess: true,
        saveTestId: action.testId,
      };

      if (action.test) {
        newState.loadedTest = action.test;
      }

      return newState;
    }
    case TEST_SETUP_SAVE_FAILURE:
      const saveError = action.error;
      return {
        ...state,
        saveFetching: false,
        saveSuccess: false,
        saveError,
      };
    case TEST_SETUP_RESET:
      return { ...initialStateTestSetup };
    default:
      return state;
  }
}
