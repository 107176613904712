import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import isUndefined from "lodash/isUndefined";
import { useDispatch } from "react-redux";
import { useRevalidator } from "react-router-dom";

import ConfirmModal from "@/components/ConfirmModal";
import { Modal2 } from "@/components/Modal";
import { useModal2Controller } from "@/components/Modal/hooks";
import { ShareClip } from "@/containers/ShareClip";
import { Video, VideoNote } from "@/containers/Video/types";
import { ButtonLink, Stack } from "@/design-system";
import { InlineIcon } from "@/design-system/components/InlineIcon";
import {
  parseVideoClipVideoApi,
  VideoClipVideoApi,
} from "@/entities/video/clip";
import { usePrevious } from "@/helpers";
import { useHistoryStateAndClear } from "@/hooks/useHistoryStateAndClear";
import { VIDEO_CLIP_DELETE_REQUEST } from "@/reducers/videoClipDelete";
import { VIDEO_NOTE_DELETE_REQUEST } from "@/reducers/videoNoteDelete";
import { useAppSelector } from "@/store";
import { SvgIconClip, SvgIconNote } from "../icons";

import { AiPromotion } from "./AiPromotion";
import { EditNoteOrClip } from "./Edit";
import { GenerateAutomatedInsights } from "./GenerateAutomatedInsights";
import { NoteAndClipsListItem } from "./ListItem";
import { NoHighlightsPlaceholder } from "./NoHighlightsPlaceholder";
import { ShowAutomatedInsightsButton } from "./ShowAutomatedInsightsButton";
import { NoteOrClipPrototypeProps, NoteType } from "./types";
import { NotesAndClipsItem } from "./utils/filterAndCountApiNotesByType";

import styles from "./styles.module.css";

interface NotesAndClipsProps {
  classNameNotesListItem?: string;
  videoId: Video["id"];
  notesAndClips: NotesAndClipsItem[];
  notesAndClipsCount: Record<NoteType, number>;
  videoAiStatus: Video["ai_status"];
  automatedInsightsCount: number;
  showGenerateAutomatedInsights: boolean;
  showAutomatedInsights: boolean;
  showPromotion: boolean;
  showShowAutomatedInsightsButton: boolean;
  onClickTime: (timestamp: number) => void;
  onClickTimeRange: (timestamp: number, timestampEnd: number) => void;
  onClickToggleAutomatedInsights: () => void;
  onClickHideAiPromotion: () => void;
  onClickUpgrade: () => void;
  videoDuration: number;
  currentVideoSecond: number;
  activeFilterTypes: NoteType | null;
  onClickFilterType: (value: NoteType) => void;
  onClickShowAllFilterType: () => void;
  isDisplayPlaceholder: boolean;
  isDisplayFilters: boolean;
  highlightedNotesAndClipsItem: { type: NoteType; id: number } | null;
}

/**
 *
 * NotesAndClips
 */
export function NotesAndClips({
  classNameNotesListItem,
  videoId,
  notesAndClips,
  notesAndClipsCount,
  videoAiStatus,
  automatedInsightsCount,
  showGenerateAutomatedInsights,
  showAutomatedInsights,
  showPromotion,
  showShowAutomatedInsightsButton,
  onClickTime,
  onClickTimeRange,
  onClickToggleAutomatedInsights,
  onClickHideAiPromotion,
  onClickUpgrade,
  activeFilterTypes,
  onClickFilterType,
  onClickShowAllFilterType,
  videoDuration,
  currentVideoSecond,
  isDisplayPlaceholder,
  isDisplayFilters,
  highlightedNotesAndClipsItem,
}: NotesAndClipsProps) {
  const dispatch = useDispatch();
  const revalidator = useRevalidator();

  const refHighlightedNotesAndClipsItem = useRef<HTMLDivElement | null>(null);

  const refLastCreatedNote = useRef<HTMLDivElement | null>(null);
  const refLastCreatedClip = useRef<HTMLDivElement | null>(null);

  const [editNoteOrClipInitialPrototype, setEditNoteOrClipInitialPrototype] =
    useState<NoteOrClipPrototypeProps | undefined>();

  const [isCancel, setIsCancel] = useState(false);
  const [deleteItem, setDeleteItem] = useState<NotesAndClipsItem | null>(null);
  const [shareClipId, setShareClipId] = useState<number | null>(null);

  const refIsClipsTabEditInitiated = useRef(false);
  const clipsTabEditId = useHistoryStateAndClear("clipsTabEditId");
  const [clipsTabEditIdState, setClipsIdEditIdState] = useState<number | null>(
    clipsTabEditId,
  );

  const lastCreatedNoteId = useAppSelector(
    (state) => state.video.lastCreatedNoteId,
  );
  const lastCreatedClipId = useAppSelector(
    (state) => state.video.lastCreatedClipId,
  );
  const prevPrototypeNoteId = usePrevious(
    editNoteOrClipInitialPrototype?.originalNoteId,
  );
  const prevPrototypeClipId = usePrevious(
    editNoteOrClipInitialPrototype?.originalClipId,
  );

  const isNoteUpdateFetching = useAppSelector(
    (state) => state.videoNoteUpdate.isFetching,
  );
  const prevIsNoteUpdateFetching = usePrevious(isNoteUpdateFetching);
  const isNoteUpdateError = useAppSelector(
    (state) => state.videoNoteUpdate.isError,
  );

  const isClipUpdateFetching = useAppSelector(
    (state) => state.videoClipUpdate.isFetching,
  );
  const isClipUpdateError = useAppSelector(
    (state) => state.videoClipUpdate.isError,
  );

  const prevIsClipUpdateFetching = usePrevious(isClipUpdateFetching);

  function handleClickCancelEdit() {
    !isCancel && setIsCancel(true);
    setEditNoteOrClipInitialPrototype(undefined);
    setClipsIdEditIdState(null);
  }

  function handleClickEditItem(item: NotesAndClipsItem) {
    initiateEditItem(item);
  }

  const initiateEditItem = useCallback(
    (item: NotesAndClipsItem) => {
      isCancel && setIsCancel(false);

      const prototype: NoteOrClipPrototypeProps = {
        type: item.type,
        content: item.note ?? "",
        timestampStart: item.timestamp,
        timestampEnd: item.timestamp_end ?? undefined,
      };

      if (item.type === "clip") {
        prototype.originalClipId = item.id;
      } else if (item.type === "note") {
        prototype.originalNoteId = item.id;
      }

      setEditNoteOrClipInitialPrototype(prototype);
    },
    [isCancel, setIsCancel],
  );

  // Edit clip if history state contains editClipId
  // which is passed from Clips tab.
  useEffect(() => {
    if (!refIsClipsTabEditInitiated.current) {
      refIsClipsTabEditInitiated.current = true;
      const clip = notesAndClips.find(
        (noteOrClip) => noteOrClip.id === clipsTabEditIdState,
      );
      clip && initiateEditItem(clip);
    }
  }, [initiateEditItem, notesAndClips, clipsTabEditIdState]);

  function handleClickShareItem(item: NotesAndClipsItem) {
    if (item.type === "clip") {
      revalidator.revalidate();
      setShareClipId(item.id);
      showShareClipModal();
    }
  }

  function handleClickItem(note: VideoNote) {
    if (note.timestamp_end != null) {
      onClickTimeRange(note.timestamp, note.timestamp_end);
    } else {
      onClickTime(note.timestamp);
    }
  }

  function handleClickDeleteItem(notesAndClipsItem: NotesAndClipsItem) {
    setDeleteItem(notesAndClipsItem);
    showConfirmDeleteNoteModal();
  }

  function handleClickCopy(notesAndClipsItem: NotesAndClipsItem) {
    if (!notesAndClipsItem.note) {
      return;
    }
    navigator.clipboard.writeText(notesAndClipsItem.note).then(() => {
      dispatch({
        type: "SNACKBAR_ADD",
        notificationType: "success",
        content: "Copied to clipboard!",
      });
    });
  }

  function handleClickDownloadClip(url: string) {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleConfirmDelete() {
    hideConfirmDeleteNoteModal();
    if (deleteItem?.type === "clip") {
      dispatch({
        type: VIDEO_CLIP_DELETE_REQUEST,
        videoId: videoId,
        clipId: deleteItem.id,
      });
    } else if (deleteItem?.type === "note") {
      dispatch({
        type: VIDEO_NOTE_DELETE_REQUEST,
        videoId: videoId,
        noteId: deleteItem.id,
      });
    }
  }

  // Scroll to highlighted Note/Clip if not mobile player
  useEffect(() => {
    let elementToScrollTo: HTMLDivElement | null = null;
    if (refHighlightedNotesAndClipsItem.current) {
      elementToScrollTo = refHighlightedNotesAndClipsItem.current;
    }

    if (elementToScrollTo) {
      const isNotMobilePlayer =
        window
          ?.getComputedStyle(elementToScrollTo)
          .getPropertyValue("--is-mobile-player") === "0";
      if (isNotMobilePlayer) {
        elementToScrollTo.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }
    }
  }, [videoId, highlightedNotesAndClipsItem]);

  // Closes Edit when update note fetching stops without error
  useEffect(() => {
    if (
      !isNoteUpdateFetching &&
      prevIsNoteUpdateFetching &&
      !isNoteUpdateError
    ) {
      setEditNoteOrClipInitialPrototype(undefined);
    }
  }, [isNoteUpdateFetching, prevIsNoteUpdateFetching, isNoteUpdateError]);

  // Closes Edit when update clip fetching stops without error
  useEffect(() => {
    if (
      !isClipUpdateFetching &&
      prevIsClipUpdateFetching &&
      !isClipUpdateError
    ) {
      // Closes edit note/clip when fetching stops without error
      setEditNoteOrClipInitialPrototype(undefined);
    }
  }, [isClipUpdateFetching, prevIsClipUpdateFetching, isClipUpdateError]);

  // update share clip to useModal2Controller

  const {
    open: showShareClipModal,
    close: hideShareClipModal,
    isOpen: isShareClipModalOpen,
  } = useModal2Controller();

  // Search for the clip with shareClipId in notesAndClips
  // TODO: Fix this type. Below is a Api type being parsed
  // to overview type as it's what the shareClip component expects.
  const shareClip = notesAndClips.find(
    (item) => item.type === "clip" && item.id === shareClipId,
  ) as VideoClipVideoApi | undefined;
  const shareClipExists = !isUndefined(shareClip);

  // If shareClipId ceases to exist in notesAndClips, close the modal and show a snackbar
  useEffect(() => {
    if (!shareClipExists && isShareClipModalOpen) {
      hideShareClipModal();
      dispatch({
        type: "SNACKBAR_ADD",
        notificationType: "error",
        content: "The clip no longer exists",
      });
    }
  }, [shareClipExists, isShareClipModalOpen, dispatch, hideShareClipModal]);

  const {
    open: showConfirmDeleteNoteModal,
    close: hideConfirmDeleteNoteModal,
    isOpen: isConfirmDeleteNoteModalOpen,
  } = useModal2Controller();

  function updateRefForNoteAndClipsListItem(
    item: NotesAndClipsItem,
    ref: HTMLDivElement | null,
  ) {
    if (
      highlightedNotesAndClipsItem !== null &&
      item.type === highlightedNotesAndClipsItem.type &&
      item.id === highlightedNotesAndClipsItem.id
    ) {
      refHighlightedNotesAndClipsItem.current = ref;
    }

    if (item.type === "note") {
      if (item.id === lastCreatedNoteId) {
        refLastCreatedNote.current = ref;
      }
    } else if (item.type === "clip") {
      if (item.id === lastCreatedClipId) {
        refLastCreatedClip.current = ref;
      }
    }
  }

  const shareClipModal = (
    <Modal2
      isActive={isShareClipModalOpen}
      onClose={hideShareClipModal}
      maxWidth={"32.5rem"}
      centered
      content={
        shareClip ? (
          <ShareClip
            {...parseVideoClipVideoApi(shareClip)}
            onClickClose={hideShareClipModal}
            videoId={videoId}
          />
        ) : null
      }
    />
  );

  return (
    <Stack spacing={"xsmall"}>
      {isDisplayFilters && (
        <Filter
          onClickType={onClickFilterType}
          count={{
            note: notesAndClipsCount.note,
            clip: notesAndClipsCount.clip,
          }}
          onClickShowAll={onClickShowAllFilterType}
          activeType={activeFilterTypes}
        />
      )}

      {showPromotion && (
        <AiPromotion
          onClickUpgrade={onClickUpgrade}
          onClickHide={onClickHideAiPromotion}
        />
      )}

      {showGenerateAutomatedInsights && (
        <GenerateAutomatedInsights
          videoId={videoId}
          videoAiStatus={videoAiStatus}
        />
      )}

      {showShowAutomatedInsightsButton && (
        <ShowAutomatedInsightsButton
          count={automatedInsightsCount}
          onClick={onClickToggleAutomatedInsights}
        />
      )}

      {isDisplayPlaceholder && !showGenerateAutomatedInsights && (
        <NoHighlightsPlaceholder />
      )}
      <div className={styles.notes}>
        {notesAndClips?.map((item: NotesAndClipsItem) => {
          const isItemInEditState =
            (item.type === "clip" &&
              editNoteOrClipInitialPrototype?.originalClipId === item.id) ||
            (item.type === "note" &&
              editNoteOrClipInitialPrototype?.originalNoteId === item.id);
          if (isItemInEditState) {
            return (
              <EditNoteOrClip
                key={`${item.type}-${item.id}`}
                videoId={videoId}
                className={styles.editNote}
                initialNoteOrClipPrototype={
                  editNoteOrClipInitialPrototype as NoteOrClipPrototypeProps
                }
                onClickCancel={handleClickCancelEdit}
                onClickTime={onClickTime}
                onClickTimeRange={onClickTimeRange}
                videoDuration={videoDuration}
                currentVideoSecond={currentVideoSecond}
                onClickDelete={() => handleClickDeleteItem(item)}
              />
            );
          } else {
            const isListItemHighlighted =
              highlightedNotesAndClipsItem !== null &&
              item.type === highlightedNotesAndClipsItem.type &&
              item.id === highlightedNotesAndClipsItem.id;
            return (
              <NoteAndClipsListItem
                className={cn(styles.listItem, classNameNotesListItem)}
                ref={(ref) => updateRefForNoteAndClipsListItem(item, ref)}
                key={item.id}
                noteOrClip={item}
                isHighlighted={isListItemHighlighted}
                isHidden={
                  !isListItemHighlighted &&
                  item.automated_insight &&
                  !showAutomatedInsights
                }
                isGlint={
                  (item.type === "note" && item.id === lastCreatedNoteId) ||
                  (item.type === "clip" && item.id === lastCreatedClipId) ||
                  (item.type === "note" &&
                    !editNoteOrClipInitialPrototype &&
                    prevPrototypeNoteId === item.id &&
                    !isCancel) ||
                  (item.type === "clip" &&
                    !editNoteOrClipInitialPrototype &&
                    prevPrototypeClipId === item.id &&
                    !isCancel)
                }
                onClick={() => handleClickItem(item)}
                onClickEdit={() => handleClickEditItem(item)}
                onClickDelete={() => handleClickDeleteItem(item)}
                onClickShare={() => handleClickShareItem(item)}
                onClickCopy={() => handleClickCopy(item)}
                // XXX: The type of data here isn't what the component expects.
                // We need to parse the data to the expected type. Currently
                // this component consumes API data directly but has been specified
                // local types.
                onClickDownload={() =>
                  // @ts-ignore
                  handleClickDownloadClip(item.download_clip_url)
                }
              />
            );
          }
        })}
      </div>
      {shareClipModal}
      <ConfirmModal
        isActive={isConfirmDeleteNoteModalOpen}
        onClose={hideConfirmDeleteNoteModal}
        labelConfirm={"Delete"}
        onConfirm={handleConfirmDelete}
        children={`Are you sure you want to delete this ${deleteItem?.type}?`}
      />
    </Stack>
  );
}

/**
 *
 * Filters
 *
 * 1. All filters need to be active by default.
 * 2. Active means that a particular type of Note is displayed.
 * 3. Multiple can be selected or deselected.
 * 4. On deselecting the last active type, it resets all to be shown, which has the same
 *    effect as the `Show All` button.
 */
function Filter({
  count,
  activeType,
  onClickShowAll,
  onClickType,
}: {
  count: {
    note: number;
    clip: number;
  };
  activeType: NoteType | null;
  onClickType: (value: NoteType) => void;
  onClickShowAll: () => void;
}) {
  return (
    <div className={styles.filter}>
      <div className={styles.filterButtons}>
        {!!count.note && (
          <FilterButton
            onClick={() => onClickType("note")}
            isActive={activeType === "note"}
            svg={SvgIconNote}
          >
            {count.note}
          </FilterButton>
        )}
        {!!count.clip && (
          <FilterButton
            onClick={() => onClickType("clip")}
            isActive={activeType === "clip"}
            svg={SvgIconClip}
          >
            {count.clip}
          </FilterButton>
        )}
      </div>
      {activeType !== null && (
        <ButtonLink
          className={styles.filterClearButton}
          onClick={onClickShowAll}
        >
          Show all
          <FontAwesomeIcon icon={solid("close")} />
        </ButtonLink>
      )}
    </div>
  );
}

/**
 *
 * Filter Button
 */
function FilterButton({
  children,
  isActive,
  onClick,
  svg,
}: {
  children: number;
  isActive?: boolean;
  onClick: () => void;
  svg: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}) {
  return (
    <button
      onClick={onClick}
      className={cn(
        styles.filterButton,
        isActive && styles.filterButton_active,
      )}
    >
      <InlineIcon Svg={svg} className={styles.filterButtonIcon} />
      &nbsp;{children}
    </button>
  );
}
