import update from "immutability-helper";
import isArray from "lodash/isArray";
import { combineReducers } from "redux";

import { listOfAllCountries } from "../helpers";

import { appUpdateReducer } from "./appUpdate";
import { checkoutReducer } from "./checkout";
import { confirmEmailReducer } from "./confirmEmail";
import { devTestFillReducer } from "./devTestFill";
import { downloadVideoClipReducer } from "./downloadVideoClipReducer";
import createGenericApiSlice from "./genericApiSlice";
import { giftCodeRedeemReducer } from "./giftCodeRedeem";
import { globalModalReducer } from "./globalModal";
import { orderTestersReducer } from "./orderTesters";
import { orderTestsReducer } from "./orderTests";
import { sharedClipReducer } from "./sharedClip";
import { sharedVideoReducer } from "./sharedVideo";
import { testClipsReducer } from "./testClips";
import { testClipsApiReducer } from "./testClipsApi";
import { testInsightsReducer } from "./testInsights";
import { testInsightsRefreshReducer } from "./testInsightsRefresh";
import { testReportReducer } from "./testReportReducer";
import { testSetupReducer } from "./testSetup";
import { SIGN_OUT, userReducer } from "./user";
import { videoClipCreateReducer } from "./videoClipCreate";
import { videoClipDeleteReducer } from "./videoClipDelete";
import { videoClipShareReducer } from "./videoClipShare";
import { videoClipUpdateReducer } from "./videoClipUpdate";
import { videoNoteCreateReducer } from "./videoNoteCreate";
import { videoNoteDeleteReducer } from "./videoNoteDelete";
import { videoNoteUpdateReducer } from "./videoNoteUpdate";
import { videoReducer } from "./videoReducer";
import { videoShareReducer } from "./videoShare";
import { videosReducer } from "./videosReducer";
import { videoStatsReducer } from "./videoStatsReducer";
import { welcomeMessageReducer } from "./welcomeMessage";

// Tutorial - redux-saga-tutorial-for-beginners-and-dog-lovers

// action types
const API_CALL_REQUEST = "API_CALL_REQUEST";
const API_CALL_SUCCESS = "API_CALL_SUCCESS";
const API_CALL_FAILURE = "API_CALL_FAILURE";

const DOG_DELETE_SUBSCRIPTION_REQUEST = "DOG_DELETE_SUBSCRIPTION_REQUEST";
const DOG_DELETE_SUBSCRIPTION_SUCCESS = "DOG_DELETE_SUBSCRIPTION_SUCCESS";
const DOG_DELETE_SUBSCRIPTION_FAILURE = "DOG_DELETE_SUBSCRIPTION_FAILURE";

const DOG_DELETE_CREDITS_REQUEST = "DOG_DELETE_CREDITS_REQUEST";
const DOG_DELETE_CREDITS_SUCCESS = "DOG_DELETE_CREDITS_SUCCESS";
const DOG_DELETE_CREDITS_FAILURE = "DOG_DELETE_CREDITS_FAILURE";

// reducer with initial state
const initialStateDog = {
  deleteSubscriptionFetching: false,
  deleteCreditsFetching: false,
  fetching: false,
  dog: null,
  error: null,
};

function dogReducer(state = initialStateDog, action) {
  switch (action.type) {
    case API_CALL_REQUEST:
      return { ...state, fetching: true, error: null };
    case API_CALL_SUCCESS:
      return { ...state, fetching: false, dog: action.dog };
    case API_CALL_FAILURE:
      return { ...state, fetching: false, dog: null, error: action.error };
    case DOG_DELETE_SUBSCRIPTION_REQUEST:
      return { ...state, deleteSubscriptionFetching: true };
    case DOG_DELETE_SUBSCRIPTION_SUCCESS:
      return { ...state, deleteSubscriptionFetching: false };
    case DOG_DELETE_SUBSCRIPTION_FAILURE:
      return { ...state, deleteSubscriptionFetching: false };
    case DOG_DELETE_CREDITS_REQUEST:
      return { ...state, deleteCreditsFetching: true };
    case DOG_DELETE_CREDITS_SUCCESS:
      return { ...state, deleteCreditsFetching: false };
    case DOG_DELETE_CREDITS_FAILURE:
      return { ...state, deleteCreditsFetching: false };
    default:
      return state;
  }
}

// BILLING INFORMATION

const BILLING_INFORMATION_REQUEST = "BILLING_INFORMATION_REQUEST";
const BILLING_INFORMATION_FAILURE = "BILLING_INFORMATION_FAILURE";
const BILLING_INFORMATION_SUCCESS = "BILLING_INFORMATION_SUCCESS";

const BILLING_INFORMATION_UPDATE_REQUEST = "BILLING_INFORMATION_UPDATE_REQUEST";
const BILLING_INFORMATION_UPDATE_FAILURE = "BILLING_INFORMATION_UPDATE_FAILURE";
const BILLING_INFORMATION_UPDATE_SUCCESS = "BILLING_INFORMATION_UPDATE_SUCCESS";

const initialStateBillingInformation = {
  fetching: false,
  error: null,
  updateFetching: false,
  updateError: null,
  billingInformation: null,
  vatCountries: null,
  availableCountries: listOfAllCountries,
  complete: null,
};

function billingInformationReducer(
  state = initialStateBillingInformation,
  action,
) {
  switch (action.type) {
    case BILLING_INFORMATION_REQUEST:
      return { ...state, fetching: true, error: null };
    case BILLING_INFORMATION_SUCCESS: {
      const { billingInformation, vatCountries, complete } = action;
      return {
        ...state,
        fetching: false,
        billingInformation,
        vatCountries,
        complete,
      };
    }
    case BILLING_INFORMATION_FAILURE:
      return { ...state, fetching: false, error: action.error };
    case BILLING_INFORMATION_UPDATE_REQUEST:
      return {
        ...state,
        updateFetching: true,
        updateError: null,
      };
    case BILLING_INFORMATION_UPDATE_FAILURE:
      return {
        ...state,
        updateFetching: false,
        updateError: action.error,
      };
    case BILLING_INFORMATION_UPDATE_SUCCESS: {
      const { billingInformation, complete } = action;
      return {
        ...state,
        billingInformation,
        complete,
        updateFetching: false,
        updateError: null,
      };
    }
    default:
      return state;
  }
}

// MEMBERS

const MEMBER_REQUEST = "MEMBER_REQUEST";
const MEMBERS_FAILURE = "MEMBERS_FAILURE";
const MEMBERS_SUCCESS = "MEMBERS_SUCCESS";

const MEMBER_CREATE_REQUEST = "MEMBER_CREATE_REQUEST";
const MEMBER_CREATE_FAILURE = "MEMBER_CREATE_FAILURE";
const MEMBER_CREATE_SUCCESS = "MEMBER_CREATE_SUCCESS";
const MEMBER_CREATE_RESET = "MEMBER_CREATE_RESET";

const MEMBER_DELETE_REQUEST = "MEMBER_DELETE_REQUEST";
const MEMBER_DELETE_FAILURE = "MEMBER_DELETE_FAILURE";
const MEMBER_DELETE_SUCCESS = "MEMBER_DELETE_SUCCESS";

const MEMBER_RESEND_REQUEST = "MEMBER_RESEND_REQUEST";
const MEMBER_RESEND_FAILURE = "MEMBER_RESEND_FAILURE";
const MEMBER_RESEND_SUCCESS = "MEMBER_RESEND_SUCCESS";

const MEMBER_UPDATE_REQUEST = "MEMBER_UPDATE_REQUEST";
const MEMBER_UPDATE_FAILURE = "MEMBER_UPDATE_FAILURE";
const MEMBER_UPDATE_SUCCESS = "MEMBER_UPDATE_SUCCESS";

const initialStateMembers = {
  fetching: false,
  members: null,
  error: null,
  createFetching: false,
  createToken: null, // This is for development only, token is not returned by the API in production
  createError: null,
  deleteFetching: false,
  deleteError: null,
  resendFetching: false,
  resendError: null,
  updateFetching: false,
  updateError: null,
};

function membersReducer(state = initialStateMembers, action) {
  switch (action.type) {
    case MEMBER_REQUEST:
      return { ...state, fetching: true, error: null };
    case MEMBERS_SUCCESS:
      const { members } = action;
      return { ...state, fetching: false, members };
    case MEMBERS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    case MEMBER_CREATE_RESET:
      return {
        ...state,
        createFetching: initialStateMembers.createFetching,
        createError: initialStateMembers.createError,
      };
    case MEMBER_CREATE_REQUEST:
      return {
        ...state,
        createFetching: true,
        createToken: null,
        createError: null,
      };
    case MEMBER_CREATE_SUCCESS:
      return { ...state, createFetching: false, createToken: action.token };
    case MEMBER_CREATE_FAILURE:
      return {
        ...state,
        createFetching: false,
        createError: action.error,
      };
    case MEMBER_DELETE_REQUEST:
      return {
        ...state,
        deleteFetching: true,
        deleteError: null,
      };
    case MEMBER_DELETE_SUCCESS:
      return { ...state, deleteFetching: false };
    case MEMBER_DELETE_FAILURE:
      return {
        ...state,
        deleteFetching: false,
        deleteError: action.error,
      };

    case MEMBER_RESEND_REQUEST:
      return {
        ...state,
        resendFetching: true,
        resendError: null,
      };
    case MEMBER_RESEND_SUCCESS:
      return { ...state, resendFetching: false };
    case MEMBER_RESEND_FAILURE:
      return {
        ...state,
        resendFetching: false,
        resendError: action.error,
      };

    case MEMBER_UPDATE_REQUEST:
      return {
        ...state,
        updateFetching: true,
        updateError: null,
      };
    case MEMBER_UPDATE_SUCCESS:
      return {
        ...state,
        updateFetching: false,
        members:
          state.members?.map((member) =>
            member.id === action.id
              ? {
                  ...member,
                  role: action.role,
                }
              : member,
          ) ?? null,
      };
    case MEMBER_UPDATE_FAILURE:
      return {
        ...state,
        updateFetching: false,
        updateError: action.error,
      };
    default:
      return state;
  }
}

// INVOICES

const INVOICES_REQUEST = "INVOICES_REQUEST";
const INVOICES_FAILURE = "INVOICES_FAILURE";
const INVOICES_SUCCESS = "INVOICES_SUCCESS";

// reducer with initial state
const initialStateInvoices = {
  fetching: false,
  invoices: null,
  totalCount: null,
  perPage: null,
  error: null,
};

function invoicesReducer(state = initialStateInvoices, action) {
  switch (action.type) {
    case INVOICES_REQUEST:
      return { ...state, fetching: true, error: null };
    case INVOICES_SUCCESS:
      const { invoices, totalCount, perPage } = action;
      return { ...state, fetching: false, invoices, totalCount, perPage };
    case INVOICES_FAILURE:
      return { ...state, fetching: false, error: action.error };
    default:
      return state;
  }
}

// INVITATION

const INVITATION_REQUEST = "INVITATION_REQUEST";
const INVITATION_FAILURE = "INVITATION_FAILURE";
const INVITATION_SUCCESS = "INVITATION_SUCCESS";

// reducer with initial state
const initialStateInvitation = {
  fetching: false,
  invitation: null,
  error: null,
};

function invitationReducer(state = initialStateInvitation, action) {
  switch (action.type) {
    case INVITATION_REQUEST:
      return { ...state, fetching: true, error: null };
    case INVITATION_SUCCESS:
      const { invitation } = action;
      return { ...state, fetching: false, invitation };
    case INVITATION_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// DELIVERY
const DELIVERY_CREATE_REQUEST = "DELIVERY_CREATE_REQUEST";
const DELIVERY_CREATE_FAILURE = "DELIVERY_CREATE_FAILURE";
export const DELIVERY_CREATE_SUCCESS = "DELIVERY_CREATE_SUCCESS";
const DELIVERY_CREATE_CLEAR_ERROR = "DELIVERY_CREATE_CLEAR_ERROR";

const DELIVERY_STOP_REQUEST = "DELIVERY_STOP_REQUEST";
const DELIVERY_STOP_FAILURE = "DELIVERY_STOP_FAILURE";
export const DELIVERY_STOP_SUCCESS = "DELIVERY_STOP_SUCCESS";
const DELIVERY_STOP_CLEAR_ERROR = "DELIVERY_STOP_CLEAR_ERROR";

const DELIVERY_RESUME_REQUEST = "DELIVERY_RESUME_REQUEST";
const DELIVERY_RESUME_SUCCESS = "DELIVERY_RESUME_SUCCESS";
const DELIVERY_RESUME_FAILURE = "DELIVERY_RESUME_FAILURE";

const initialStateDelivery = {
  lastRequestFetching: false,
  lastRequestTestId: null,
  lastRequestError: null,

  stopFetching: false,
  stopFetchingDeliveryId: null,
  stopError: null,

  resumeFetching: false,
  resumeDeliveryId: null,
  resumeError: null,
};

function deliveryReducer(state = initialStateDelivery, action) {
  switch (action.type) {
    case DELIVERY_STOP_CLEAR_ERROR:
      return { ...state, stopError: null };
    case DELIVERY_CREATE_CLEAR_ERROR:
      return { ...state, lastRequestError: null };
    case DELIVERY_STOP_REQUEST:
      return {
        ...state,
        stopFetching: true,
        stopFetchingDeliveryId: action.deliveryId,
        stopError: null,
      };
    case DELIVERY_CREATE_REQUEST:
      return {
        ...state,
        lastRequestFetching: true,
        lastRequestTestId: action.testId,
        lastRequestError: null,
      };
    case DELIVERY_STOP_FAILURE:
      return {
        ...state,
        stopFetching: false,
        stopError: action.error,
      };
    case DELIVERY_CREATE_FAILURE:
      if (action.testId === state.lastRequestTestId) {
        return {
          ...state,
          lastRequestFetching: false,
          lastRequestError: action.error,
        };
      } else {
        return state;
      }
    case DELIVERY_STOP_SUCCESS:
      return {
        ...state,
        stopFetching: false,
      };
    case DELIVERY_CREATE_SUCCESS:
      return {
        ...state,
        lastRequestFetching: false,
        lastRequestTestId: null,
        lastRequestError: null,
      };
    case DELIVERY_RESUME_REQUEST:
      return {
        ...state,
        resumeFetching: true,
        resumeDeliveryId: action.deliveryId,
        resumeError: null,
      };
    case DELIVERY_RESUME_SUCCESS:
      return { ...state, resumeFetching: false };
    case DELIVERY_RESUME_FAILURE:
      return { ...state, resumeFetching: false, resumeError: action.error };
    default:
      return state;
  }
}

// TARGETING
const TARGETING_REQUEST = "TARGETING_REQUEST";
const TARGETING_FAILURE = "TARGETING_FAILURE";
const TARGETING_SUCCESS = "TARGETING_SUCCESS";

// reducer with initial state
const initialStateTargeting = {
  fetching: false,
  showWarning: null,
  error: null,
};

function targetingReducer(state = initialStateTargeting, action) {
  switch (action.type) {
    case TARGETING_REQUEST:
      return { ...state, fetching: true, error: null };
    case TARGETING_SUCCESS:
      const { showWarning } = action;
      return { ...state, fetching: false, showWarning };
    case TARGETING_FAILURE:
      return {
        ...state,
        fetching: false,
        showWarning: null,
        error: action.error,
      };
    default:
      return state;
  }
}

// TESTS
const TESTS_REQUEST = "TESTS_REQUEST";
// const TESTS_RE_REQUEST = "TESTS_RE_REQUEST";
const TESTS_FAILURE = "TESTS_FAILURE";
const TESTS_SUCCESS = "TESTS_SUCCESS";
const TESTS_CLEAR = "TESTS_CLEAR";

const TEST_DELETE = "TEST_DELETE";
const TEST_DELETE_SUCCESS = "TEST_DELETE_SUCCESS";
const TEST_DELETE_FAILURE = "TEST_DELETE_FAILURE";

const TEST_ARCHIVE = "TEST_ARCHIVE";
const TEST_ARCHIVE_SUCCESS = "TEST_ARCHIVE_SUCCESS";
const TEST_ARCHIVE_FAILURE = "TEST_ARCHIVE_FAILURE";

const TEST_UNARCHIVE = "TEST_UNARCHIVE";
const TEST_UNARCHIVE_SUCCESS = "TEST_UNARCHIVE_SUCCESS";
const TEST_UNARCHIVE_FAILURE = "TEST_UNARCHIVE_FAILURE";

const TEST_INVITATION_UPDATE_STATUS = "TEST_INVITATION_UPDATE_STATUS";
export const TEST_INVITATION_UPDATE_STATUS_SUCCESS =
  "TEST_INVITATION_UPDATE_STATUS_SUCCESS";
const TEST_INVITATION_UPDATE_STATUS_FAILURE =
  "TEST_INVITATION_UPDATE_STATUS_FAILURE";

const TESTS_SET_UI_FIRST_ACTIVE = "TESTS_SET_UI_FIRST_ACTIVE";
const TESTS_UNSET_UI_FIRST_ACTIVE = "TESTS_UNSET_UI_FIRST_ACTIVE";

// reducer with initial state
const initialStateTests = {
  fetching: false,
  tests: null,
  testsRefreshHash: null,
  testsRequestAction: null,
  totalCount: null,
  totalCountTests: null,
  totalCountDraft: null,
  totalCountArchived: null,
  perPage: null,
  error: null,
  uiFirstActive: false,
  updateStatusFetching: false,
  updateStatusFetchingTestId: null,
  updateStatusInvitationActive: null,
  updateStatusError: null,
  deleteFetching: false,
  deleteError: null,
  archiveFetching: false,
  unarchiveFetching: false,
};

function testsReducer(state = initialStateTests, action) {
  switch (action.type) {
    case TESTS_SET_UI_FIRST_ACTIVE:
      return { ...state, uiFirstActive: true };
    case TESTS_UNSET_UI_FIRST_ACTIVE:
      return { ...state, uiFirstActive: false };
    case TESTS_REQUEST:
      if (action.silent === true) {
        return {
          ...state,
          fetching: true,
          error: null,
        };
      }
      return {
        ...state,
        tests: null,
        testsRefreshHash: null,
        testsRequestAction: null,
        fetching: true,
        error: null,
      };
    case TESTS_SUCCESS:
      if (!action.tests) {
        return { ...state, fetching: false };
      }
      const {
        tests,
        totalCount,
        totalCountTests,
        totalCountDraft,
        totalCountArchived,
        perPage,
        refreshHash,
        requestAction,
      } = action;
      return {
        ...state,
        fetching: false,
        tests,
        testsRefreshHash: refreshHash,
        testsRequestAction: requestAction,
        totalCount,
        totalCountTests,
        totalCountDraft,
        totalCountArchived,
        perPage,
      };
    case TESTS_FAILURE:
      return { ...state, fetching: false, error: action.error };
    case TESTS_CLEAR:
      return {
        ...state,
        tests: null,
        totalCount: null,
        perPage: null,
        testsRequestAction: null,
        testsRefreshHash: null,
      };
    case TEST_INVITATION_UPDATE_STATUS:
      return {
        ...state,
        updateStatusInvitationActive:
          initialStateTests.updateStatusInvitationActive,
        updateStatusError: initialStateTests.updateStatusError,
        updateStatusFetching: true,
        updateStatusFetchingTestId: action.testId,
      };
    case TEST_INVITATION_UPDATE_STATUS_SUCCESS: {
      const { test } = action;
      const updateStatusInvitationActive = test?.invitation?.active;
      const updatedState = {
        ...state,
        updateStatusInvitationActive,
        updateStatusFetching: false,
        updateStatusFetchingTestId: null,
      };
      if (isArray(state.tests) && updateStatusInvitationActive === false) {
        const testIndex = state.tests.findIndex(
          (testItem) => testItem.id === test.id,
        );
        if (testIndex !== -1) {
          updatedState.tests = update(updatedState.tests, {
            [testIndex]: { $set: test },
          });
        }
      }
      return updatedState;
    }
    case TEST_INVITATION_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        updateStatusError: action.error,
        updateStatusFetching: false,
        updateStatusFetchingTestId: null,
      };
    case TEST_DELETE:
      return { ...state, deleteFetching: true, deleteError: null };
    case TEST_ARCHIVE:
      return { ...state, archiveFetching: true };
    case TEST_UNARCHIVE:
      return { ...state, unarchiveFetching: true };
    case TEST_DELETE_SUCCESS:
      return { ...state, deleteFetching: false };
    case TEST_DELETE_FAILURE:
      return { ...state, deleteFetching: false, deleteError: action.error };
    case TEST_ARCHIVE_SUCCESS:
    case TEST_ARCHIVE_FAILURE:
      return { ...state, archiveFetching: false };
    case TEST_UNARCHIVE_SUCCESS:
    case TEST_UNARCHIVE_FAILURE:
      return { ...state, unarchiveFetching: false };
    default:
      return state;
  }
}

// TEST
const TEST_REQUEST = "TEST_REQUEST";
// const TEST_RE_REQUEST = "TEST_RE_REQUEST";
const TEST_FAILURE = "TEST_FAILURE";
const TEST_SUCCESS = "TEST_SUCCESS";

const TEST_VIDEOS_REQUEST = "TEST_VIDEOS_REQUEST";
// const TEST_VIDEOS_RE_REQUEST = "TEST_VIDEOS_RE_REQUEST";
const TEST_VIDEOS_FAILURE = "TEST_VIDEOS_FAILURE";
const TEST_VIDEOS_SUCCESS = "TEST_VIDEOS_SUCCESS";

// reducer with initial state
const initialStateTest = {
  fetching: false,
  test: null,
  testRequestAction: null,
  testRefreshHash: null,
  error: null,
  videos: null,
  videosRefreshHash: null,
  videosRequestAction: null,
  videosFetching: false,
  videosError: null,
  videosTotalCount: null,
  videosPerPage: null,
};

function testReducer(state = initialStateTest, action) {
  switch (action.type) {
    case TEST_REQUEST:
      if (action.silent === true) {
        return { ...state, fetching: true, error: null };
      }
      return {
        ...state,
        fetching: true,
        error: null,
        test: null,
        testRefreshHash: null,
        testRequestAction: null,
      };
    case TEST_SUCCESS:
      if (!action.test) {
        return { ...state, fetching: false };
      }
      return {
        ...state,
        fetching: false,
        test: action.test,
        testRefreshHash: action.refreshHash,
        testRequestAction: action.requestAction,
      };
    case DELIVERY_STOP_SUCCESS:
      const { test } = action;
      if (test && state.test?.id === test.id) {
        return {
          ...state,
          test,
        };
      }
      return state;
    case TEST_FAILURE:
      return { ...state, fetching: false, error: action.error };
    case TEST_VIDEOS_REQUEST:
      if (action.silent === true) {
        return { ...state, videosFetching: true, error: null };
      }
      return {
        ...state,
        videosFetching: true,
        videos: null,
        error: null,
        videosRefreshHash: null,
        videosRequestAction: null,
      };
    case TEST_VIDEOS_FAILURE:
      return { ...state, videosFetching: false, error: action.error };
    case TEST_VIDEOS_SUCCESS:
      if (!action.videos) {
        return { ...state, videosFetching: false };
      }
      const { videos, totalCount, perPage, refreshHash, requestAction } =
        action;
      return {
        ...state,
        videosFetching: false,
        videos,
        videosTotalCount: totalCount,
        videosPerPage: perPage,
        videosRefreshHash: refreshHash,
        videosRequestAction: requestAction,
      };
    default:
      return state;
  }
}

// ORDER_ATTEMPT

const ORDER_ATTEMPT = "ORDER_ATTEMPT";
const ORDER_ATTEMPT_CLEAR = "ORDER_ATTEMPT_CLEAR";
const ORDER_ATTEMPT_FULFILL = "ORDER_ATTEMPT_FULFILL";
const ORDER_ATTEMPT_FULFILL_CLEAR = "ORDER_ATTEMPT_FULFILL_CLEAR";
const ORDER_ATTEMPT_IYOP = "ORDER_ATTEMPT_IYOP";
const ORDER_ATTEMPT_IYOP_CLEAR = "ORDER_ATTEMPT_IYOP_CLEAR";
const ORDER_ATTEMPT_IYOP_FULFILL = "ORDER_ATTEMPT_IYOP_FULFILL";
const ORDER_ATTEMPT_IYOP_FULFILL_CLEAR = "ORDER_ATTEMPT_IYOP_FULFILL_CLEAR";

const initialStateOrderAttempt = {
  iyopAttempt: false, // set to a testId to be redirected to this test after successful subscription checkout
  iyopFulfilled: false,
  attempt: false,
  fulfillment: false,
};

function orderAttemptReducer(state = initialStateOrderAttempt, action) {
  switch (action.type) {
    case ORDER_ATTEMPT: {
      const { testId, creditCount, repeatType, videoCount } = action;
      return {
        ...state,
        attempt: { testId, creditCount, repeatType, videoCount },
      };
    }
    case ORDER_ATTEMPT_CLEAR:
      return { ...state, attempt: false };
    case ORDER_ATTEMPT_FULFILL: {
      const { testId, creditCount, repeatType, videoCount } = action;
      return {
        ...state,
        fulfillment: { testId, creditCount, repeatType, videoCount },
      };
    }
    case ORDER_ATTEMPT_FULFILL_CLEAR:
      return { ...state, fulfillment: false };
    case ORDER_ATTEMPT_IYOP: {
      const { testId } = action;
      return { ...state, iyopAttempt: testId };
    }
    case ORDER_ATTEMPT_IYOP_CLEAR:
      return { ...state, iyopAttempt: false };
    case ORDER_ATTEMPT_IYOP_FULFILL: {
      const { testId } = action;
      return { ...state, iyopFulfilled: testId };
    }
    case ORDER_ATTEMPT_IYOP_FULFILL_CLEAR:
      return { ...state, iyopFulfilled: false };
    default:
      return state;
  }
}

//

const NOTIFICATION_ADD = "NOTIFICATION_ADD";
const NOTIFICATION_DISMISS = "NOTIFICATION_DISMISS";

const SNACKBAR_ADD = "SNACKBAR_ADD";
const SNACKBAR_DISMISS = "SNACKBAR_DISMISS";

const initialStateNotifications = {
  queue: [],
  snackbarQueue: [],
  incrementId: 1,
};

function notificationsReducer(state = initialStateNotifications, action) {
  switch (action.type) {
    case SNACKBAR_ADD: {
      const { content } = action;
      let { notificationType } = action;
      if (typeof notificationType === "undefined") {
        notificationType = "default";
      }
      return {
        ...state,
        snackbarQueue: update(state.snackbarQueue, {
          $push: [
            {
              type: notificationType,
              content,
              id: state.incrementId,
            },
          ],
        }),
        incrementId: state.incrementId + 1,
      };
    }
    case SNACKBAR_DISMISS:
      return {
        ...state,
        snackbarQueue: update(state.snackbarQueue, { $splice: [[-1, 1]] }),
      };
    case NOTIFICATION_ADD: {
      const { content } = action;
      let { notificationType } = action;
      if (typeof notificationType === "undefined") {
        notificationType = "default";
      }
      return {
        ...state,
        queue: update(state.queue, {
          $push: [{ type: notificationType, content, id: state.incrementId }],
        }),
        incrementId: state.incrementId + 1,
      };
    }
    case NOTIFICATION_DISMISS:
      return {
        ...state,
        queue: update(state.queue, { $splice: [[-1, 1]] }),
      };
    default:
      return state;
  }
}

// PAYMENT

const PAYMENT_SETUP_INTENT_REQUEST = "PAYMENT_SETUP_INTENT_REQUEST";
const PAYMENT_SETUP_INTENT_FAILURE = "PAYMENT_SETUP_INTENT_FAILURE";
const PAYMENT_SETUP_INTENT_SUCCESS = "PAYMENT_SETUP_INTENT_SUCCESS";

const PAYMENT_SETUP_CARD_REQUEST = "PAYMENT_SETUP_CARD_REQUEST";
const PAYMENT_SETUP_CARD_FAILURE = "PAYMENT_SETUP_CARD_FAILURE";
const PAYMENT_SETUP_CARD_SUCCESS = "PAYMENT_SETUP_CARD_SUCCESS";

const initialStatePayment = {
  setupClientSecretFetching: false,
  setupClientSecretError: null,
  setupClientSecret: null,

  setupCardFetching: false,
  setupCardError: false,
};

function paymentReducer(state = initialStatePayment, action) {
  switch (action.type) {
    case PAYMENT_SETUP_INTENT_REQUEST:
      return {
        ...state,
        setupClientSecretFetching: true,
        setupClientSecretError: null,
      };
    case PAYMENT_SETUP_INTENT_FAILURE:
      return {
        ...state,
        setupClientSecretFetching: false,
        setupClientSecretError: action.error,
      };
    case PAYMENT_SETUP_INTENT_SUCCESS:
      return {
        ...state,
        setupClientSecretFetching: false,
        setupClientSecretError: false,
        setupClientSecret: action.clientSecret,
      };
    case PAYMENT_SETUP_CARD_REQUEST:
      return { ...state, setupCardFetching: true, setupCardError: null };
    case PAYMENT_SETUP_CARD_FAILURE:
      return {
        ...state,
        setupCardFetching: false,
        setupCardError: action.error,
      };
    case PAYMENT_SETUP_CARD_SUCCESS:
      return { ...state, setupCardFetching: false, setupCardError: false };
    default:
      return state;
  }
}

export const reducers = {
  appUpdate: appUpdateReducer,
  billingInformation: billingInformationReducer,
  checkout: checkoutReducer,
  dog: dogReducer,
  giftCodeRedeem: giftCodeRedeemReducer,
  members: membersReducer,
  invoices: invoicesReducer,
  invitation: invitationReducer,
  user: userReducer,
  video: videoReducer,
  videoClipCreate: videoClipCreateReducer,
  videoClipUpdate: videoClipUpdateReducer,
  videoClipDelete: videoClipDeleteReducer,
  videoClipShare: videoClipShareReducer,
  videoShare: videoShareReducer,
  videoNoteCreate: videoNoteCreateReducer,
  videoNoteUpdate: videoNoteUpdateReducer,
  videoNoteDelete: videoNoteDeleteReducer,
  videos: videosReducer,
  videoStats: videoStatsReducer,
  targeting: targetingReducer,
  tests: testsReducer,
  test: testReducer,
  testReport: testReportReducer,
  testClips: testClipsReducer,
  testClipsApi: testClipsApiReducer,
  testInsights: testInsightsReducer,
  testInsightsRefresh: testInsightsRefreshReducer,
  testScreeners: createGenericApiSlice("TEST_SCREENERS").reducer,
  allTestScreeners: createGenericApiSlice("ALL_TEST_SCREENERS").reducer,
  testSetup: testSetupReducer,
  orderAttempt: orderAttemptReducer,
  orderTests: orderTestsReducer,
  orderTesters: orderTestersReducer,
  payment: paymentReducer,
  delivery: deliveryReducer,
  notifications: notificationsReducer,
  releaseNotes: createGenericApiSlice("RELEASE_NOTES").reducer,
  dogCustomerDelete: createGenericApiSlice("DOG_CUSTOMER_DELETE").reducer,
  cancelSubscription: createGenericApiSlice("CANCEL_SUBSCRIPTION").reducer,
  confirmEmail: confirmEmailReducer,
  confirmEmailResend: createGenericApiSlice("CONFIRM_EMAIL_RESEND").reducer,
  reactivateSubscription: createGenericApiSlice("REACTIVATE_SUBSCRIPTION")
    .reducer,
  welcomeMessage: welcomeMessageReducer,
  downloadVideoClip: downloadVideoClipReducer,
  globalModal: globalModalReducer,
  devTestFill: devTestFillReducer,
  sharedClip: sharedClipReducer,
  sharedVideo: sharedVideoReducer,
};

export default function createReducer() {
  const appReducer = combineReducers(reducers);

  // rootReducer
  return (state, action) => {
    if (action.type === SIGN_OUT) {
      state = {
        router: state.router,
      };
    }
    return appReducer(state, action);
  };
}
