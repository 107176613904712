import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  CONFIRM_EMAIL_FAILURE,
  CONFIRM_EMAIL_REQUEST,
  CONFIRM_EMAIL_SUCCESS,
} from "@/reducers/confirmEmail";
import { API_URL } from ".";

export function* watchConfirmEmail() {
  yield takeLatest(CONFIRM_EMAIL_REQUEST, callConfirmEmailApi);
}

function* callConfirmEmailApi(action: any): SagaIterator {
  const { token } = action;

  try {
    const response = yield call(postConfirmEmail, token);
    const { received_sessions } = response.data;

    yield put({ type: CONFIRM_EMAIL_SUCCESS, data: response.data });

    if (received_sessions === true) {
      yield put({
        type: "WELCOME_MESSAGE_SHOW",
        welcomeMessageType: "freetrial",
      });
    } else if (received_sessions === false) {
      yield put({
        type: "WELCOME_MESSAGE_SHOW",
        welcomeMessageType: "freetrial_no_sessions",
      });
    } else {
      // received_sessions is not true or false
      // this is the case for other team members
      yield put({
        type: "NOTIFICATION_ADD",
        notificationType: "success",
        content: "Your email has been verified.",
      });
    }
  } catch (error) {
    yield put({
      type: CONFIRM_EMAIL_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function postConfirmEmail(token: string) {
  // XXX: Confirm email should probably not be called with access token
  return axios({
    method: "post",
    url: API_URL + "/user/confirm",
    data: {
      token,
    },
  });
}
