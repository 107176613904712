import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  DropDown,
  DropDownItem,
  ErrorMessage,
  FieldHelp,
  FormField,
  InputText,
  Stack,
} from "@/design-system";
import { isVatNeeded, noop, vatRateForCountry } from "../../helpers";

class BillingInformationForm extends Component {
  static defaultProps = {
    fieldFeedback: {},
    fieldValues: {},
    availableCountries: [],
    vatCountries: [],
    onChange: noop,
  };

  onChangeFirstName = (e) => {
    this.props.onChange({
      ...this.props.fieldValues,
      firstName: e.target.value,
    });
  };
  onChangeLastName = (e) => {
    this.props.onChange({
      ...this.props.fieldValues,
      lastName: e.target.value,
    });
  };
  onChangeOrganization = (e) => {
    this.props.onChange({
      ...this.props.fieldValues,
      organization: e.target.value,
    });
  };
  onChangeStreet = (e) => {
    this.props.onChange({ ...this.props.fieldValues, street: e.target.value });
  };
  onChangePostcode = (e) => {
    this.props.onChange({
      ...this.props.fieldValues,
      postcode: e.target.value,
    });
  };
  onChangeCity = (e) => {
    this.props.onChange({ ...this.props.fieldValues, city: e.target.value });
  };
  onChangeCountry = (e) => {
    const country = e.target.value;
    this.props.onChange({ ...this.props.fieldValues, country, vatId: "" });
  };
  onChangeVatId = (e) => {
    this.props.onChange({ ...this.props.fieldValues, vatId: e.target.value });
  };

  getVatPlaceholder(country) {
    switch (country) {
      case "AT":
        return "ATU12345678";
      case "BE":
        return "BE123456789";
      case "BG":
        return "BG123456789";
      case "HR":
        return "HR12345678901";
      case "CY":
        return "CY12345678X";
      case "CZ":
        return "CZ12345678";
      case "DK":
        return "DK12345678";
      case "EE":
        return "EE123456789";
      case "FI":
        return "FI12345678";
      case "FR":
        return "FRXX123456789";
      case "DE":
        return "DE123456789";
      case "GB":
        return "GB123456789";
      case "GR":
        return "EL123456789";
      case "HU":
        return "HU12345678";
      case "IE":
        return "IE1X23456X";
      case "IT":
        return "IT12345678901";
      case "LV":
        return "LV12345678901";
      case "LT":
        return "LT123456789";
      case "LU":
        return "LU12345678";
      case "MT":
        return "MT12345678";
      case "NL":
        return "NL123456789X12";
      case "PL":
        return "PL1234567890";
      case "PT":
        return "PT123456789";
      case "RO":
        return "RO1234567890";
      case "SK":
        return "SK1234567890";
      case "SI":
        return "SI12345678";
      case "ES":
        return "ESX12345678X";
      case "SE":
        return "SE123456789012";
      default:
        return undefined;
    }
  }

  render() {
    const {
      fieldFeedback,
      availableCountries,
      vatCountries,
      autoFocus,
      fullWidth,
      errorMessage,
      fieldValues,
    } = this.props;
    const {
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    } = fieldValues;

    const vatPlaceholder = this.getVatPlaceholder(country);

    const isVatNeededForCountry = isVatNeeded(country, vatCountries);
    const isVatCanBeEntered = country === "AT";

    return (
      <Stack>
        {errorMessage && <ErrorMessage message={errorMessage} />}

        <FormField
          errorMessage={fieldFeedback?.first_name}
          label={"First name"}
        >
          <InputText
            value={firstName || ""}
            onChange={this.onChangeFirstName}
            placeholder={"John"}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
          />
        </FormField>

        <FormField errorMessage={fieldFeedback?.last_name} label={"Last name"}>
          <InputText
            value={lastName || ""}
            onChange={this.onChangeLastName}
            placeholder={"Lennon"}
            fullWidth={fullWidth}
          />
        </FormField>

        <FormField
          errorMessage={fieldFeedback?.organization}
          label={"Organization"}
        >
          <InputText
            value={organization || ""}
            below={<FieldHelp>Optional</FieldHelp>}
            onChange={this.onChangeOrganization}
            placeholder={"Beatles"}
            fullWidth={fullWidth}
          />
        </FormField>

        <FormField errorMessage={fieldFeedback?.street} label={"Street"}>
          <InputText
            value={street || ""}
            onChange={this.onChangeStreet}
            placeholder={"3 Abbey Road"}
            fullWidth={fullWidth}
          />
        </FormField>

        <FormField errorMessage={fieldFeedback?.postcode} label={"ZIP code"}>
          <InputText
            value={postcode || ""}
            onChange={this.onChangePostcode}
            placeholder={"NW8 9AY"}
            fullWidth={fullWidth}
          />
        </FormField>

        <FormField errorMessage={fieldFeedback?.city} label={"City"}>
          <InputText
            value={city || ""}
            onChange={this.onChangeCity}
            placeholder={"London"}
            fullWidth={fullWidth}
          />
        </FormField>

        <FormField errorMessage={fieldFeedback?.country} label={"Country"}>
          <DropDown
            value={country}
            isSearchable={true}
            onChange={this.onChangeCountry}
            placeholder={"Select your country…"}
            fullWidth={fullWidth}
          >
            {availableCountries.map((country) => (
              <DropDownItem value={country.isoAlpha2} key={country.isoAlpha2}>
                {country.name}
              </DropDownItem>
            ))}
          </DropDown>
        </FormField>

        {(isVatNeededForCountry || isVatCanBeEntered) && (
          <FormField
            errorMessage={fieldFeedback?.vatno}
            label={`VAT identification number${
              isVatCanBeEntered ? " (optional)" : ""
            }`}
          >
            <InputText
              placeholder={vatPlaceholder}
              fullWidth={fullWidth}
              value={vatId || ""}
              onChange={this.onChangeVatId}
            />
            {isVatNeededForCountry && (
              <FieldHelp>
                Without valid VAT identification number you will have{" "}
                {vatRateForCountry(country, vatCountries)}% VAT added to your
                invoice.
              </FieldHelp>
            )}
          </FormField>
        )}
      </Stack>
    );
  }
}

BillingInformationForm.propTypes = {
  vatCountries: PropTypes.array,
  availableCountries: PropTypes.array,
  fieldFeedback: PropTypes.object,
  fieldValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BillingInformationForm;
