import { forwardRef, MouseEvent, ReactNode, useEffect, useRef } from "react";
import { concatClassNames as cn } from "@system42/core";

import { NoteWithHashtags } from "@/components/NoteWithHashtags";
import { VideoClip, VideoNote } from "@/containers/Video/types";
//import { Tooltip } from "@/design-system";
import { formatDateWithoutTime } from "@/helpers";
import { mergeRefs, toHHMMSS } from "@/helpers-ts";
import { isApiNoteOrClipAi } from "@/utils/apiNote";
import { SvgIconClip, SvgIconNote } from "../../icons";
import { NotesAndClipsItem } from "../utils/filterAndCountApiNotesByType";

import {
  SvgIconClipDownload,
  SvgIconCopy,
  SvgIconNoteDelete,
  SvgIconNoteEdit,
  SvgIconNoteShare,
  SvgIconPublic,
} from "./icons";

import notesStyles from "../styles.module.css";
import styles from "./styles.module.css";

export interface NoteAndClipsListItemProps {
  className?: string;
  noteOrClip: NotesAndClipsItem;
  onClick: () => void;
  onClickEdit: ActionsProps["onClickEdit"];
  onClickShare: ActionsProps["onClickShare"];
  onClickDownload: ActionsProps["onClickDownload"];
  isHighlighted: boolean;
  isHidden: boolean;
  isGlint: boolean;
  onClickDelete: ActionsProps["onClickDelete"];
  onClickCopy: ActionsProps["onClickCopy"];
}

/**
 *
 * Note List Item
 */
export const NoteAndClipsListItem = forwardRef<
  HTMLDivElement,
  NoteAndClipsListItemProps
>(
  (
    {
      noteOrClip,
      onClick,
      onClickEdit,
      onClickShare,
      isHighlighted,
      isHidden,
      onClickDelete,
      onClickCopy,
      className,
      isGlint,
      onClickDownload,
    },
    ref,
  ) => {
    return (
      <Container
        className={className}
        onClick={onClick}
        isHighlighted={isHighlighted}
        isHidden={isHidden}
        isAutomatedInsight={isApiNoteOrClipAi(noteOrClip)}
        isGlint={isGlint}
        ref={ref}
      >
        <Meta noteOrClip={noteOrClip} />
        <NoteText text={noteOrClip.note ?? ""} />
        <Actions
          isDeleteable={noteOrClip.deletable}
          isEditable={noteOrClip.editable}
          isShareable={noteOrClip.type === "clip"}
          isCopyable={Boolean(noteOrClip.note) && noteOrClip.type === "note"}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          onClickShare={onClickShare}
          onClickCopy={onClickCopy}
          isClip={noteOrClip.type === "clip"}
          isDownloadable={noteOrClip.type === "clip"}
          // XXX: The data passed into this component does not comply with the type.
          // The parent component should be updated to parse the correct data.
          isDownloadReady={
            noteOrClip.type === "clip" && noteOrClip.status === "finished"
          }
          onClickDownload={onClickDownload}
        />
      </Container>
    );
  },
);

interface ContainerProps {
  isGlint: boolean;
  children: ReactNode;
  className?: string;
  onClick: () => void;
  isHighlighted: boolean;
  isHidden: boolean;
  isAutomatedInsight: boolean;
}

/**
 *
 * Container
 */
const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (props, forwardedRef) => {
    const { className, children, onClick, isHighlighted, isHidden, isGlint } =
      props;

    const localRef = useRef<HTMLDivElement>(null);

    function handleClick(e: MouseEvent<HTMLDivElement>) {
      // don't call click if text is beeing selected
      if (window.getSelection()?.toString()) {
        return;
      }
      onClick?.();
    }

    useEffect(() => {
      if (isGlint === true) {
        localRef.current?.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }
    }, [localRef, isGlint]);

    return (
      <div
        className={cn(
          className,
          styles.note,
          isHighlighted && styles.note_highlighted,
          isHidden && styles.note_hidden,
          isGlint && notesStyles.glint,
        )}
        ref={mergeRefs([localRef, forwardedRef])}
        onClick={handleClick}
      >
        {children}
      </div>
    );
  },
);

type ActionsProps = {
  isClip: boolean;
  isCopyable: boolean;
  isDeleteable: boolean;
  isDownloadable: boolean;
  isDownloadReady: boolean;
  isEditable: boolean;
  isShareable: boolean;
  onClickCopy: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickDelete: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickDownload: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickEdit: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickShare: (e: MouseEvent<HTMLButtonElement>) => void;
};

/**
 *
 * Note Actions
 */
function Actions({
  isClip,
  isCopyable,
  isDeleteable,
  isDownloadable,
  isDownloadReady,
  isEditable,
  isShareable,
  onClickCopy,
  onClickDelete,
  onClickDownload,
  onClickEdit,
  onClickShare,
}: ActionsProps) {
  function handleClickShare(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClickShare(e);
  }

  function handleClickEdit(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClickEdit(e);
  }

  function handleClickDelete(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClickDelete(e);
  }

  function handleClickCopy(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClickCopy(e);
  }

  function handleClickDownload(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClickDownload(e);
  }

  if (isEditable || isDeleteable || isShareable) {
    return (
      <div className={styles.noteActions}>
        {isShareable && (
          <button
            onClickCapture={handleClickShare}
            className={cn(
              styles.noteActionsButton,
              styles.noteActionsButton_share,
            )}
            title={"Share this clip"}
          >
            <SvgIconNoteShare className={styles.squareIcon20Inline} /> Share
          </button>
        )}
        {isDownloadable && isDownloadReady && (
          <button
            onClickCapture={handleClickDownload}
            className={styles.noteActionsButton}
            title={"Download this clip"}
          >
            <SvgIconClipDownload className={styles.squareIcon20Inline} />
          </button>
        )}

        {/* XXX: This Tooltip is a little difficult to work with when nested inside a - */}
        {/* parent component with `overflow:hidden`. This is a common issue that has been - */}
        {/* solved with the new Popover API which is available since April 2024. */}
        {/* We should perhaps consider implementing a new Popover (and related components) - */}
        {/* using it because it uses the new top-layer css concept. */}

        {/* TODO: For now we hid the button and we want to enable it once the video auto rerequests
        while a clip is processing */}

        {/* {isDownloadable && !isDownloadReady && (
          <button disabled={true} className={styles.noteActionsButton}>
            <Tooltip
              content={"Processing in progress, download available soon."}
            >
              <SvgIconClipDownload className={styles.squareIcon20Inline} />
            </Tooltip>
          </button>
        )} */}
        {isCopyable && (
          <button
            onClickCapture={handleClickCopy}
            className={styles.noteActionsButton}
            title={"Copy to clipboard"}
          >
            <SvgIconCopy className={styles.squareIcon20Inline} />
          </button>
        )}
        {isEditable && (
          <button
            onClickCapture={handleClickEdit}
            className={styles.noteActionsButton}
            title={`Edit this ${isClip ? "clip" : "note"}`}
          >
            <SvgIconNoteEdit className={styles.squareIcon20Inline} />
          </button>
        )}
        {isDeleteable && (
          <button
            onClickCapture={handleClickDelete}
            className={styles.noteActionsButton}
            title={`Delete this ${isClip ? "clip" : "note"}`}
          >
            <SvgIconNoteDelete className={styles.squareIcon20Inline} />
          </button>
        )}
      </div>
    );
  } else {
    return null;
  }
}

/**
 *
 * Edited Tag
 */
function EditedTag({ date, user }: { date: Date; user: VideoNote["user"] }) {
  return (
    <span
      title={`Edited by ${user} on ${formatDateWithoutTime(new Date(date))}`}
    >
      edited
    </span>
  );
}

/**
 *
 * Meta
 */
function Meta({ noteOrClip }: { noteOrClip: NotesAndClipsItem }) {
  const isAi = isApiNoteOrClipAi(noteOrClip);
  const { edited_at: editedAt, user } = noteOrClip;
  const isShared = Boolean((noteOrClip as VideoClip).shared);

  let iconAndTimestamp;
  if (noteOrClip.type === "clip") {
    iconAndTimestamp = (
      <>
        <SvgIconClip className={styles.iconClip} />
        <span className={styles.noteTimestamps}>{`${toHHMMSS(
          noteOrClip.timestamp,
        )} - ${toHHMMSS(noteOrClip.timestamp_end)}`}</span>
      </>
    );
  } else {
    iconAndTimestamp = (
      <>
        <SvgIconNote className={styles.iconNote} />
        <span className={styles.noteTimestamps}>
          {toHHMMSS(noteOrClip.timestamp)}
        </span>
      </>
    );
  }

  const sharedElement = (
    <>
      <span>·</span>
      <SvgIconPublic title="Shared" />
    </>
  );

  return (
    <div className={styles.note_timecode}>
      {iconAndTimestamp}
      {isShared && sharedElement}
      <span>·</span>
      <span
        className={cn(
          styles.noteUser,
          isAi && styles.noteUser_automatedInsight,
        )}
      >
        {isAi ? "AI" : user}
      </span>
      {isAi && editedAt !== null && <EditedTag date={editedAt} user={user} />}
    </div>
  );
}

/**
 *
 * Note Text
 */
function NoteText({ text }: { text: string }) {
  return (
    <p>
      <NoteWithHashtags note={text} classNameHashtag={styles.hashtag} />
    </p>
  );
}
