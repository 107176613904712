import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal2 } from "../components/Modal";
import { ModalContent } from "../components/ModalContent";
import UpdateBillingInformationForm from "../components/UpdateBillingInformationForm";
import { usePrevious } from "../helpers";

import { useBillingInformation } from "./useBillingInformation";
import { useModal2 } from "./useModal2";

export function useEditBillingAddressModal() {
  const dispatch = useDispatch();

  const billingInformation = useBillingInformation();
  const availableCountries = useSelector(
    (state) => state.billingInformation.availableCountries,
  );
  const vatCountries = useSelector(
    (state) => state.billingInformation.vatCountries,
  );

  const updateFetching = useSelector(
    (state) => state.billingInformation.updateFetching,
  );
  const prevUpdateFetching = usePrevious(updateFetching);
  const updateError = useSelector(
    (state) => state.billingInformation.updateError,
  );

  function handleSaveBillingInformation(formData) {
    const {
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    } = formData;
    dispatch({
      type: "BILLING_INFORMATION_UPDATE_REQUEST",
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    });
  }

  const allDataAvailable =
    billingInformation && availableCountries && vatCountries;

  const { modal, showModal, hideModal, isModalActive } = useModal2(
    <Modal2
      loading={!allDataAvailable}
      content={
        allDataAvailable && (
          <ModalContent>
            <UpdateBillingInformationForm
              errorMessage={updateError?.message}
              fieldFeedback={updateError?.fieldFeedback}
              saving={updateFetching}
              onSave={handleSaveBillingInformation}
              defaultValues={billingInformation}
              availableCountries={availableCountries}
              vatCountries={vatCountries}
              showCancelButton={true}
              fullWidth
              onClickCancel={handleCancel}
            />
          </ModalContent>
        )
      }
    />,
  );

  // Load data if modal is activated
  useEffect(() => {
    // Fetch if billingInformation is not fetched yet
    // might be outdated but this is enough for this case.
    if (isModalActive && !billingInformation) {
      dispatch({ type: "BILLING_INFORMATION_REQUEST" });
    }
  }, [dispatch, isModalActive, billingInformation]);

  // Close modal on save success
  useEffect(() => {
    if (
      prevUpdateFetching === true &&
      updateFetching === false &&
      Boolean(updateError) === false
    ) {
      hideModal();
    }
  });

  function handleCancel() {
    hideModal();
  }

  return {
    modal,
    showModal,
    hideModal,
    isModalActive,
  };
}
