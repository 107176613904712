import { MutableRefObject } from "react";
import { useDispatch } from "react-redux";

import { IconTextMessageWithButton } from "@/components/IconTextMessageWithButton";
import TranscriptTimeline from "@/components/TranscriptTimeline";
import { SvgIconTranscript } from "@/containers/Video/icons";
import { Video } from "@/containers/Video/types";
import { VideoShared } from "@/entities/video/shared";
import { useAppSelector } from "@/store";

import styles from "../../styles.module.css";

/**


  Transcript Props

*/
export interface TranscriptProps {
  videoId: Video["id"] | VideoShared["id"];
  videoDuration: Video["duration"];
  videoTranscript: Video["transcript"];
  currentVideoSecond: number;
  onClickTime: (timestamp: number) => void;
  isCurrentItemVisible: boolean;
  onChangeCurrentItemVisible: (isVisible: boolean) => void;
  scrollerRef: MutableRefObject<HTMLDivElement | null>;
}

/**


  Transcript

*/
// XXX: When this is used for the shared video, the fetch transcript logic should probably not be here…
function Transcript({
  videoId,
  videoDuration,
  videoTranscript,
  currentVideoSecond,
  onClickTime,
  isCurrentItemVisible,
  onChangeCurrentItemVisible,
  scrollerRef,
}: TranscriptProps) {
  const dispatch = useDispatch();

  const transcriptFetching = useAppSelector(
    (state) =>
      state.video.transcriptFetching &&
      state.video.transcriptVideoId === videoId,
  );

  const transcriptErrorMessage = useAppSelector((state) =>
    state.video.transcriptVideoId === videoId
      ? state.video.transcriptError?.message
      : "",
  );

  function handleClickCreateTranscript() {
    dispatch({ type: "VIDEO_TRANSCRIPT_REQUEST", videoId });
  }

  return (
    <>
      {videoTranscript && videoTranscript.length > 0 && (
        <TranscriptTimeline
          videoDuration={videoDuration || 0}
          currentVideoTime={currentVideoSecond}
          transcript={videoTranscript}
          onClickTime={onClickTime}
          scrollerRef={scrollerRef}
          preventScrollIntoView={!isCurrentItemVisible}
          onChangeCurrentTimelineItemVisible={onChangeCurrentItemVisible}
        />
      )}
      {videoTranscript && videoTranscript.length === 0 && (
        <div className={styles.noTranscriptAvailable}>
          No transcript available.
        </div>
      )}
      {!videoTranscript && (
        <>
          {transcriptFetching === true && (
            <div className={styles.transcriptCreateMessage}>
              Transcription in progress…
            </div>
          )}
          {transcriptFetching === false && (
            <IconTextMessageWithButton
              SvgIcon={SvgIconTranscript}
              errorMessage={transcriptErrorMessage}
              buttonText={`Create transcript`}
              onClick={handleClickCreateTranscript}
            >
              Generate a fast and accurate machine-generated transcript for this
              video.
            </IconTextMessageWithButton>
          )}
        </>
      )}
    </>
  );
}

export default Transcript;
