import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Modal2 } from "@/components/Modal";
import { useModal2Controller } from "@/components/Modal/hooks";
import { Button } from "@/design-system";
import { usePrevious } from "@/helpers";
import { useAppSelector } from "@/store";
import Loading from "../Loading";
import Notifications from "../Notifications";

import { UserbrainVideoPlayerApi } from "./components/VideoPlayer/types";
import { SvgIconBack } from "./icons";
import { Player } from "./Player";
import { ShareVideo } from "./ShareVideo";
import { Sidebar } from "./Sidebar";
import { NoteType } from "./Sidebar/Notes/types";
import { useJumpPoints } from "./utils/useJumpPoints";
import { useTaskStepsWithResponse } from "./utils/useTaskStepsWithResponse";

import styles from "./styles.module.css";

/**


  Video Container

*/
export default function Video() {
  /*

    Init

  */
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  /*

    State

  */
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideoSecond, setCurrentVideoSecond] = useState(0);

  /*

    Refs

  */
  const refPlayer = useRef<UserbrainVideoPlayerApi | null>(null);

  /*

    Selectors

  */
  const video = useAppSelector((state) => state.video.video);

  const videoUpdateError = useAppSelector((state) => state.video.updateError);

  const videoError = useAppSelector((state) => state.video.error);

  const fetchingEnableShare = useAppSelector(
    (state) => state.video.updateFetchingEnableShare,
  );

  const fetchingDisableShare = useAppSelector(
    (state) => state.video.updateFetchingDisableShare,
  );

  /*

  Constants

  */
  const prevFetchingDisableShare = usePrevious(fetchingDisableShare);
  const prevFetchingEnableShare = usePrevious(fetchingEnableShare);

  const { taskStepsWithResponse, isLegacyTask } =
    useTaskStepsWithResponse(video);

  const { nextJumpPoint, previousJumpPoint } = useJumpPoints(
    taskStepsWithResponse,
    currentVideoSecond,
    video?.duration,
  );

  const publicClipsCount = useMemo(() => {
    return video?.clips?.filter((clip) => clip.shared).length ?? 0;
  }, [video?.clips]);

  const highlightedNoteParamValue = searchParams.get("note");
  const highlightedClipParamValue = searchParams.get("clip");
  const isAutoplay = searchParams.get("autoplay") === "1";

  // If both highlightedNoteId and highlightedClipId are present,
  // highlightedNote will be set
  const highlightedNotesAndClipsItem = useMemo(() => {
    let highlightedNotesAndClipsItem: { type: NoteType; id: number } | null;
    if (highlightedNoteParamValue !== null) {
      highlightedNotesAndClipsItem = {
        type: "note",
        id: Number(highlightedNoteParamValue),
      };
    } else if (highlightedClipParamValue !== null) {
      highlightedNotesAndClipsItem = {
        type: "clip",
        id: Number(highlightedClipParamValue),
      };
    } else {
      highlightedNotesAndClipsItem = null;
    }
    return highlightedNotesAndClipsItem;
  }, [highlightedNoteParamValue, highlightedClipParamValue]);

  const [
    timestampStartHighlightedNotesAndClipsItem,
    timestampEndHighlightedNotesAndClipsItem,
  ] = useMemo(() => {
    if (highlightedNotesAndClipsItem && video?.notes && video?.clips) {
      if (highlightedNotesAndClipsItem.type === "note") {
        const note = video.notes.find(
          (note) => note.id === highlightedNotesAndClipsItem.id,
        );
        return [note?.timestamp, undefined];
      } else if (highlightedNotesAndClipsItem.type === "clip") {
        const clip = video.clips.find(
          (clip) => clip.id === highlightedNotesAndClipsItem.id,
        );
        return [clip?.timestamp, clip?.timestamp_end];
      }
    }
    return [undefined, undefined];
  }, [highlightedNotesAndClipsItem, video?.clips, video?.notes]);

  // Autoplay does work without this as well. But this effect is here to make sure
  // that the video stops when the highlighted item is a clip and it ends
  const refPlayUntilForHighlightedClipExecuted = useRef(false);
  useEffect(() => {
    if (
      !refPlayUntilForHighlightedClipExecuted.current &&
      timestampEndHighlightedNotesAndClipsItem &&
      isAutoplay
    ) {
      refPlayer.current?.playUntil(timestampEndHighlightedNotesAndClipsItem);
      refPlayUntilForHighlightedClipExecuted.current = true;
    }
  }, [timestampEndHighlightedNotesAndClipsItem, isAutoplay]);

  /*

    Handlers

  */
  function handleClickTime(timestamp: number) {
    refPlayer.current?.currentTime(timestamp);
    refPlayer.current?.play();
  }

  function handleClickTimeWithAnEndInMind(
    timestamp: number,
    timestampEnd: number,
  ) {
    refPlayer.current?.currentTime(timestamp);
    refPlayer.current?.playUntil(timestampEnd);
  }

  function handleClickTogglePlay() {
    refPlayer.current?.togglePlay();
  }

  function handleClickPlay() {
    refPlayer.current?.play();
  }

  function handleClickPause() {
    refPlayer.current?.pause();
  }

  function handleToggleIsShared() {
    dispatch({
      type: "VIDEO_UPDATE_REQUEST",
      id: video?.id,
      shared: !Boolean(video?.shared),
    });
  }

  const goBack = useCallback(() => {
    const hasHistory = location.key !== "default";
    navigate(hasHistory ? -1 : ("/" as any));
  }, [location, navigate]);

  function handleClickBack() {
    goBack();
  }

  function handleClickShare() {
    openVideoShareModal();
  }

  /*

  Effects

  */

  useEffect(() => {
    dispatch({ type: "VIDEO_DELETE_CLEAR_ERROR" });
  }, [dispatch, video?.id]);

  useEffect(() => {
    const linkGenerationFailed =
      fetchingEnableShare === false &&
      prevFetchingEnableShare === true &&
      videoUpdateError;
    if (linkGenerationFailed) {
      dispatch({
        type: "SNACKBAR_ADD",
        notificationType: "error",
        content: "Failed to generate link.",
      });
    }
  }, [
    fetchingEnableShare,
    prevFetchingEnableShare,
    videoUpdateError,
    dispatch,
  ]);

  useEffect(() => {
    const linkGenerationFailed =
      fetchingDisableShare === false &&
      prevFetchingDisableShare === true &&
      videoUpdateError;
    if (linkGenerationFailed) {
      dispatch({
        type: "SNACKBAR_ADD",
        notificationType: "error",
        content: "Failed to disable link.",
      });
    }
  }, [
    fetchingDisableShare,
    prevFetchingDisableShare,
    videoUpdateError,
    dispatch,
  ]);

  /*

    Show Modal

  */
  const {
    isOpen: isVideoShareModalOpen,
    open: openVideoShareModal,
    close: closeVideoShareModal,
  } = useModal2Controller();

  const shareVideoModalElement = (
    <Modal2
      isActive={isVideoShareModalOpen}
      onClose={closeVideoShareModal}
      maxWidth={"35rem"}
      content={
        video ? (
          <ShareVideo
            onClickClose={closeVideoShareModal}
            posterUrl={video.poster}
            videoDuration={video.duration}
            shareHash={video.shared_hash}
            currentVideoSecond={currentVideoSecond}
            isShared={video?.shared === true}
            onToggleIsShared={handleToggleIsShared}
            publicClipsCount={publicClipsCount}
            videoId={video.id}
            isFetching={!!fetchingEnableShare || !!fetchingDisableShare}
          />
        ) : null
      }
    />
  );

  if (!video || String(video?.id) !== params.id) {
    return <Loading error={videoError}>Loading video</Loading>;
  }

  /*

    ReactNode

  */
  return (
    <>
      <Helmet>
        <title>
          {video.title ? "Video for " + video.title + "" : "Video"} | Userbrain
        </title>
      </Helmet>
      <Notifications hideNotifications={true} />
      <VideoContainer>
        <Header
          title={video.title}
          onClickBack={handleClickBack}
          onClickShare={handleClickShare}
          backButtonLabel={location.key !== "default" ? "Go back" : "Dashboard"}
        />
        <Player
          video={video}
          isAutoplay={isAutoplay}
          nextJumpPoint={nextJumpPoint}
          previousJumpPoint={previousJumpPoint}
          onChangeIsPlaying={setIsPlaying}
          onChangeCurrentVideoSecond={setCurrentVideoSecond}
          refPlayer={refPlayer}
          timestampStartHighlightedNotesAndClipsItem={
            timestampStartHighlightedNotesAndClipsItem
          }
        />
        <Sidebar
          video={video}
          isPlaying={isPlaying}
          currentVideoSecond={currentVideoSecond}
          videoDuration={video?.duration ?? 0}
          taskStepsWithResponse={taskStepsWithResponse}
          isLegacyTask={isLegacyTask}
          onClickTogglePlay={handleClickTogglePlay}
          onClickPlay={handleClickPlay}
          onClickPause={handleClickPause}
          onClickTime={handleClickTime}
          onClickTimeRange={handleClickTimeWithAnEndInMind}
          highlightedNotesAndClipsItem={highlightedNotesAndClipsItem}
          onBack={goBack} // TODO improve this
        />
      </VideoContainer>
      {shareVideoModalElement}
    </>
  );
}

/**


  Video Container

*/
function VideoContainer({ children }: { children: ReactNode }) {
  return (
    <div className={styles.video} data-testid="video-container">
      {children}
    </div>
  );
}

/**


  Header

*/
function Header({
  onClickBack,
  onClickShare,
  title,
  backButtonLabel,
}: {
  onClickBack: () => void;
  onClickShare: () => void;
  title: string;
  backButtonLabel: string;
}) {
  return (
    <div className={styles.header}>
      <div className={styles.backLinkAndTitle}>
        <button className={styles.backLink} onClick={onClickBack}>
          <SvgIconBack className={styles.backLinkIcon} />
          {backButtonLabel}
        </button>
        <h1 className={styles.title}>{title}</h1>
      </div>
      <Button className={styles.shareButton} onClick={onClickShare}>
        <FontAwesomeIcon icon={solid("share")} fixedWidth /> Share
      </Button>
    </div>
  );
}
